import { EXPRESSIONS, MODEL_LINKS } from 'modules/common/constants';
import type {
  MetraDispatch,
  MetraMedia,
  ModelLinksReducer,
  ModelPropertyValue,
  ModelSchemaCategories,
  RootReducer,
  ThunkAction,
} from 'types';
import { findValidUrls } from 'utils/utils';
import { matchPath } from 'react-router';
import { getMediaByID } from 'modules/media/media-extra';
import { isImmutable } from 'seamless-immutable';
import { cloneDeep } from 'lodash';
import { getType } from 'modules/basicSearch/helpers';
import { isNumeric } from 'helpers/utils';
import { getTextUrlMixDisplayValue } from 'components/ui/textUrlMix';
import { modelPathMatch, ModelRouteParams } from './helpers';

export type GetModelLinksParams = {
  propValues: Record<string, ModelPropertyValue>;
  expressionValues: Record<string, Record<string, string>>;
  allMedia: Record<Numberish, MetraMedia>;
  dispatch: MetraDispatch;
};

interface GetModelLinksReturnValue extends ModelLinksReducer {
  byId: Record<number, { id: number; fallbackUrl: string }>;
}

export function getModelLinks({
  propValues,
  expressionValues,
  allMedia,
  dispatch,
}: GetModelLinksParams): GetModelLinksReturnValue {
  return Object.entries(propValues).reduce(
    (acc: GetModelLinksReturnValue, [entityId, value]) => {
      const entityPropValues = Object.entries(value);

      for (const [propSchemaId, rawStringValue] of entityPropValues) {
        const expressionValue = expressionValues[entityId]?.[
          propSchemaId
        ]?.replaceAll('"', '');
        const propValue = expressionValue ? expressionValue : rawStringValue;
        const links = Array.from(new Set(findValidUrls(propValue)));

        for (const link of links) {
          try {
            const url = new URL(link);
            const match = modelPathMatch(url);
            if (match) {
              const {
                params: { mediaId },
              } = match;

              // ignore links that have punctuation at the end
              if (!isNumeric(mediaId)) continue;
              const modelId = Number(mediaId);

              if (!acc.ids.includes(modelId)) {
                acc.ids = [...acc.ids, modelId];
                acc.byId[modelId] = {
                  id: modelId,
                  fallbackUrl: link,
                };

                // if we don't have the media on hand, fetch it
                if (!allMedia[modelId]) {
                  dispatch(
                    getMediaByID(modelId, {
                      meta: { excludeGuild: true },
                      entity: 'media',
                      error: undefined,
                    })
                  );
                }
              }
            }
          } catch {
            // It should be a valid URL, but just in case, should we log it?
          }
        }
      }

      return acc;
    },
    {
      ids: [],
      byId: {},
      notes: {},
    }
  );
}

/**
 * Returns a modified record of Prop Values which includes the values of
 * Column A. This includes node, edge, and set labels.
 * @param state - current model state <RootReducer>
 * @returns modified PropValues including Col A / Name Column values
 */
export function addNameColumnToPropValues(
  state: RootReducer
): Record<string, ModelPropertyValue> {
  const model = state.modelReducer;
  const updatedValues = Object.clone(model.propValues);

  const ids = Object.keys(model.nodes)
    .concat(Object.keys(model.edges))
    .concat(Object.keys(model.sets))
    .concat(Object.keys(model.modelProps))
    .concat(Object.keys(model.modelCalcs))
    .concat(Object.keys(model.paths));

  const nameSchemas: Record<string, string> = {
    nodes: 'name/column/NODES',
    edges: 'name/column/EDGES',
    sets: 'name/column/SETS',
    modelProps: 'name/column/MODEL_PROPS',
    modelCalcs: 'name/column/MODEL_CALCS',
    paths: 'name/column/PATHS',
  };

  ids.forEach((id) => {
    const type = getType(state, id) as ModelSchemaCategories;
    updatedValues[id] = {
      [nameSchemas[type]]: model[type][id].label,
      ...updatedValues[id],
    };
  });

  return updatedValues;
}

export const setModelLinks = (): ThunkAction<void> => {
  return (dispatch, getState) => {
    const state = getState();
    const propValues = addNameColumnToPropValues(state);
    const expressionValues = state.modelReducer.expressions.values;
    const allMedia = isImmutable(state.entityReducer.media)
      ? state.entityReducer.media.asMutable({ deep: true })
      : cloneDeep(state.entityReducer.media);
    const getLinkedModelsParams = {
      propValues,
      expressionValues,
      allMedia,
      dispatch,
    };

    const payload = getModelLinks(getLinkedModelsParams);

    return dispatch({
      type: MODEL_LINKS.SET,
      payload,
    });
  };
};

export const setHyperlinksExpression = (): ThunkAction<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const propValues = addNameColumnToPropValues(state);
    const expressionValues = state.modelReducer.expressions.values;

    const parsedPropValues = Object.entries(propValues)
      .flatMap(([entityId, value]) => {
        const entityPropValues = Object.entries(value);

        return entityPropValues.flatMap(([propSchemaId, rawStringValue]) => {
          const expressionValue = expressionValues[entityId]?.[
            propSchemaId
          ]?.replaceAll('"', '');
          const propValue = expressionValue ? expressionValue : rawStringValue;

          return Array.from(new Set(findValidUrls(propValue))).map((link) => {
            try {
              const url = new URL(link);

              // will also match
              // http://domain.com/org/orgname/models/43/version/:versionId
              const modelMatch = matchPath<ModelRouteParams>(url.pathname, {
                path: '/org/:orgName/models/:mediaId',
                exact: false,
              });
              const deprecatedModelMatch = matchPath<ModelRouteParams>(
                url.pathname,
                {
                  path: '/org/:orgId/guild/:guildid/projects/:projectid/models/:mediaId',
                  exact: true,
                }
              );
              const fileMatch = matchPath<ModelRouteParams>(url.pathname, {
                path: '/api/org/:orgId/guild/:guildCName/media/:mediaId/_download',
                exact: false,
              });

              let mediaId = null;

              if (deprecatedModelMatch) {
                const {
                  params: { mediaId: id },
                } = deprecatedModelMatch;
                mediaId = id;
              }

              if (modelMatch) {
                const {
                  params: { mediaId: id },
                } = modelMatch;
                mediaId = id;
              }

              if (fileMatch) {
                const {
                  params: { mediaId: id },
                } = fileMatch;
                mediaId = id;
              }

              if (mediaId === null) {
                // possibly a folder-url
                const urlElements = url.pathname.split('/');
                const lastElement = urlElements[urlElements.length - 1];
                if (lastElement !== 'files') {
                  mediaId = lastElement;
                }
              }

              return {
                entityId,
                propSchemaId,
                mediaId,
                propValue,
              };
            } catch {
              return null;
            }
          });
        });
      })
      .filter(Boolean) as {
      mediaId?: string;
      propSchemaId: string;
      entityId: string;
      propValue: string;
    }[];

    const media = getState().entityReducer.media;
    const versions = getState().entityReducer.versions;
    const missingIDs: Numberish[] = [];
    parsedPropValues.forEach((link) => {
      if (!link.mediaId) return;
      if (missingIDs.includes(link.mediaId)) return;
      if (isNumeric(link.mediaId) && !media[link.mediaId]) {
        missingIDs.push(link.mediaId);
      }
    });
    const fetchAllMediaAcrossGuilds = await Promise.all(
      missingIDs.map((id) =>
        dispatch(
          getMediaByID(Number(id), {
            meta: { excludeGuild: true },
            entity: 'media',
            error: undefined,
          })
        )
      )
    );

    const allMedia = isImmutable(media)
      ? media.asMutable({ deep: true })
      : cloneDeep(media);

    const textUrlMixDisplayValueMap = parsedPropValues.reduce(
      (acc: Record<string, any>, link) => {
        const displayText = getTextUrlMixDisplayValue(
          link.propValue,
          allMedia,
          versions
        );

        acc[link.entityId] = {
          [link.propSchemaId]: displayText,
        };

        return acc;
      },
      {}
    );

    return dispatch({
      type: EXPRESSIONS.SET_HYPERLINKS,
      payload: textUrlMixDisplayValueMap,
    });
  };
};
