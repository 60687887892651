import {
  BaseModelReducer,
  CollapseTuple,
  CrumbLink,
  EntityCollectionType,
  MetraSimpleAction,
  MetraActionFunc,
  ModelNode,
  PointLike,
  SelectedShapes,
  ThunkActionFunc,
  WatcherSize,
  MetraVoidAction,
  ThunkAction,
} from 'types';
import { MODEL, STAGE_EVENT } from 'utils/constants';
import { ENGINE, EntityType, GLOBAL_EVENT, SHAPE } from 'utils/constants-extra';
import { showModal } from 'modules/app/actions';
import { ensureSelectionConsistency } from './other';
import { clearModelSearchResults } from '../modelSearchResults';
import { MODEL_HISTORY } from 'modules/model/history/actions';

export const updateModelVersionId: MetraActionFunc<
  [versionId: string],
  string
> = (versionId) => ({
  type: MODEL.SET_MODEL_VERSION_ID,
  payload: versionId,
});

export const setLabelSize: MetraActionFunc<[fontSize: number], number> = (
  fontSize
) => ({
  type: MODEL.SET_LABEL_FONT_SIZE,
  payload: fontSize,
});

export const setDrawingStrokeWidth: MetraActionFunc<[width: string], string> = (
  width
) => ({
  type: MODEL.SET_DRAWING_STROKE_WIDTH,
  payload: width,
});

export const setDrawingStrokeColor: MetraActionFunc<[color: string], string> = (
  color
) => ({
  type: MODEL.SET_DRAWING_STROKE_COLOR,
  payload: color,
});

export const setDrawingStrokeAlpha: MetraActionFunc<[alpha: number], number> = (
  alpha
) => ({
  type: MODEL.SET_DRAWING_STROKE_ALPHA,
  payload: alpha,
});

export const setDrawingFillColor: MetraActionFunc<[color: string], string> = (
  color
) => ({
  type: MODEL.SET_DRAWING_FILL_COLOR,
  payload: color,
});

export const setDrawingFillAlpha: MetraActionFunc<[alpha: number], number> = (
  alpha
) => ({
  type: MODEL.SET_DRAWING_FILL_ALPHA,
  payload: alpha,
});

export const setDrawingMode: MetraActionFunc<[drawingMode: string], string> = (
  drawingMode
) => ({
  type: MODEL.SET_DRAWING_MODE,
  payload: drawingMode,
});

export const setTextColor: MetraActionFunc<[color: string], string> = (
  color
) => ({
  type: MODEL.SET_TEXT_COLOR,
  payload: color,
});

export const setTextAlpha: MetraActionFunc<[alpha: string], string> = (
  alpha
) => ({
  type: MODEL.SET_TEXT_ALPHA,
  payload: alpha,
});

export const setTextStrokeAlpha: MetraActionFunc<[alpha: string], string> = (
  alpha
) => ({
  type: MODEL.SET_TEXT_STROKE_ALPHA,
  payload: alpha,
});

export const setTextStrokeColor: MetraActionFunc<[color: string], string> = (
  color
) => ({
  type: MODEL.SET_TEXT_STROKE_COLOR,
  payload: color,
});

export const setTextStrokeThickness: MetraActionFunc<[size: string], string> = (
  size
) => ({
  type: MODEL.SET_TEXT_STROKE_THICKNESS,
  payload: size,
});

export const setTextFontSize: MetraActionFunc<[fontSize: string], string> = (
  fontSize
) => ({
  type: MODEL.SET_TEXT_FONT_SIZE,
  payload: fontSize,
});

export const setIsReadOnly: MetraActionFunc<[isReadOnly: boolean], boolean> = (
  isReadOnly
) => ({
  type: MODEL.SET_READ_ONLY,
  payload: isReadOnly,
});

export const setIsProtected: MetraActionFunc<
  [isProtected: boolean],
  boolean
> = (isProtected) => ({
  type: MODEL.SET_PROTECTED,
  payload: isProtected,
});

export const showContextMenu: MetraActionFunc<
  [position: PointLike],
  PointLike
> = (position) => ({
  type: MODEL.SHOW_CONTEXT_MENU,
  payload: { ...position },
});

export const hideContextMenu: ThunkActionFunc<[], void> =
  () =>
  (dispatch, _gs, { emit }) => {
    emit(ENGINE.CONTEXT_MENU.HIDE);
    dispatch({
      type: MODEL.HIDE_CONTEXT_MENU,
    });
  };

export const hideContextMenuActionOnly: MetraActionFunc = () => ({
  type: MODEL.HIDE_CONTEXT_MENU,
});

export const setName: MetraActionFunc<[name: string], string> = (name) => ({
  type: MODEL.SET_NAME,
  payload: name,
});

export const setNodeDefaultLayout: MetraActionFunc<[layout: string], string> = (
  layout
) => ({
  type: MODEL.SET_NODE_DEFAULT_LAYOUT,
  payload: layout,
});

export const setValidClipboardCellRangeType: MetraActionFunc<
  [validClipboardCellRangeType: Option<EntityCollectionType>],
  Option<EntityCollectionType>
> = (validClipboardCellRangeType) => ({
  type: MODEL.SET_VALID_CLIPBOARD_CELL_RANGE_TYPE,
  payload: validClipboardCellRangeType,
});

export const setCopying: MetraActionFunc<[copying: boolean], boolean> = (
  copying
) => ({
  type: MODEL.SET_COPYING,
  payload: copying,
});

export const setCutting: MetraActionFunc<[cutting: boolean], boolean> = (
  cutting
) => ({
  type: MODEL.SET_CUTTING,
  payload: cutting,
});

export const setPasting: MetraActionFunc<[pasting: boolean], boolean> = (
  pasting
) => ({
  type: MODEL.SET_PASTING,
  payload: pasting,
});

export const setModelLibraryPath: ThunkActionFunc<[CrumbLink[]], void> = (
  crumbPath
) => {
  return (dispatch) => {
    let pathString = 'Files/';
    let parentFolder = null;
    if (crumbPath && crumbPath.length > 0) {
      crumbPath.forEach((crumb) => {
        pathString = `${pathString}${crumb[1]}/`;
      });
      parentFolder = crumbPath[crumbPath.length - 1][0];
    }
    dispatch({
      type: MODEL.SET_MODEL_LIBRARY_PATH,
      payload: pathString,
    });
    dispatch(setModelParentFolder(parentFolder));
  };
};

export const setModelParentFolder: MetraActionFunc<
  [folderId: Option<Numberish>],
  Option<Numberish>
> = (folderId) => ({
  type: MODEL.SET_MODEL_PARENT_FOLDER,
  payload: folderId,
});

/**
 * create/add the passed tuples as collapsed state.
 * @param setTuples - set tuples to create.
 */
export const createCollapsedSets: MetraActionFunc<
  [setTuples: CollapseTuple[]],
  CollapseTuple[]
> = (setTuples) => ({
  type: MODEL.COLLAPSED.CREATE,
  payload: setTuples,
});

/**
 * removed the passed tupples from the collapsed state.
 * @param setTuples - set tuples to remove.
 */
export const deleteCollapsedSets: MetraActionFunc<
  [setTuples: CollapseTuple[]],
  CollapseTuple[]
> = (setTuples) => ({
  type: MODEL.COLLAPSED.DELETE,
  payload: setTuples,
});

/**
 * push node into edgeNodes in base model state
 * @param node - node to push
 */
export const pushEdgeNodes: MetraActionFunc<[node: ModelNode], ModelNode> = (
  node
) => ({
  type: MODEL.EDGE_NODES_PUSH,
  payload: node,
});

/**
 * clears edgeNodes in base model state
 */
export const clearEdgeNodes: MetraActionFunc = () => ({
  type: MODEL.EDGE_NODES_CLEAR,
});

// INIT MODEL ACTION

export const setMenuLaunchLocation: MetraActionFunc<
  [location: PointLike],
  PointLike
> = (location) => ({
  type: MODEL.MENU_LAUNCH_LOCATION,
  payload: location,
});

export const beginPendingEdge: MetraActionFunc<[id: UUID], UUID> = (id) => ({
  type: MODEL.PENDING_EDGE_BEGIN,
  payload: id,
});

export const deselectObjects: ThunkActionFunc<
  [objects?: UUID[], type?: 'shapes' | 'sets'],
  MetraSimpleAction<Pick<SelectedShapes, 'edgesSelected'>>
> =
  (objects = [], type = 'shapes') =>
  (dispatch) => {
    const sytlePanelArgs =
      type === 'shapes'
        ? { shapes: objects, sets: [] }
        : { shapes: [], sets: objects };
    dispatch(changeStylePanelToFollow(sytlePanelArgs, false, true));
    dispatch({
      type: MODEL.OBJECTS_DESELECT,
      payload: {
        type,
        objects,
      },
    });
    return dispatch(ensureSelectionConsistency());
  };

export const deselectAll =
  (): ThunkAction<MetraSimpleAction<Pick<SelectedShapes, 'edgesSelected'>>> =>
  (dispatch) => {
    dispatch({
      type: MODEL.OBJECTS_DESELECT_ALL,
    });
    return dispatch(ensureSelectionConsistency());
  };

export const select: MetraActionFunc<
  [objects: UUID, type?: 'shapes' | 'sets'],
  {
    type: 'shapes' | 'sets';
    objects: [UUID];
  }
> = (object, type = 'shapes') => ({
  type: MODEL.OBJECTS_SELECT,
  payload: {
    type,
    objects: [object],
  },
});

export const changeStylePanelToFollow: ThunkActionFunc<
  [
    affectedObjects: { shapes: UUID[]; sets: UUID[] },
    extendSelection: boolean,
    reduceSelection: boolean
  ]
> =
  ({ shapes, sets }, extendSelection, reduceSelection) =>
  (dispatch, getState) => {
    if (getState().windows?.active?.ModelStylePanel === undefined) {
      // style panel is closed; nothing to do here
      return;
    }
    const model = getState().modelReducer;

    const oldSelection = model.base.selected;
    const setsWereSelected = oldSelection.sets.length > 0;
    const nodesWereSelected = oldSelection.shapes.some(
      (shapeId) => model.shapes[shapeId]?.type === SHAPE.NODE
    );
    const edgesWereSelected = oldSelection.shapes.some(
      (shapeId) => model.shapes[shapeId]?.type === SHAPE.EDGE
    );

    const nowSelectedSets: UUID[] = [];
    const nowSelectedShapes: UUID[] = [];
    if (extendSelection) {
      nowSelectedSets.push(...oldSelection.sets);
      nowSelectedSets.push(...sets);
      nowSelectedShapes.push(...oldSelection.shapes);
      nowSelectedShapes.push(...shapes);
    } else if (reduceSelection) {
      nowSelectedSets.push(
        ...[...oldSelection.sets].filter((setId) => !sets.includes(setId))
      );
      nowSelectedShapes.push(
        ...[...oldSelection.shapes].filter(
          (shapeId) => !shapes.includes(shapeId)
        )
      );
    } else {
      nowSelectedSets.push(...sets);
      nowSelectedShapes.push(...shapes);
    }

    const setsAreSelected = nowSelectedSets.length > 0;
    const nodesAreSelected = nowSelectedShapes.some(
      (shapeId) => model.shapes[shapeId].type === SHAPE.NODE
    );
    const edgesAreSelected = nowSelectedShapes.some(
      (shapeId) => model.shapes[shapeId].type === SHAPE.EDGE
    );

    if (!(setsAreSelected || nodesAreSelected || edgesAreSelected)) {
      // nothing is now selected; don't do anything
      return;
    }

    // if something was added to selection, set style panel to that
    if (setsAreSelected && !setsWereSelected) {
      return dispatch(setStylePanelActiveType(EntityType.SETS));
    } else if (nodesAreSelected && !nodesWereSelected) {
      return dispatch(setStylePanelActiveType(EntityType.NODES));
    } else if (edgesAreSelected && !edgesWereSelected) {
      return dispatch(setStylePanelActiveType(EntityType.EDGES));
    }

    const currentStylePanelType = model.base.stylePanelActiveType;
    // if something was removed, select from what's left
    if (
      (setsWereSelected && !setsAreSelected) ||
      (nodesWereSelected && !nodesAreSelected) ||
      (edgesWereSelected && !edgesAreSelected)
    ) {
      if (
        (setsAreSelected && currentStylePanelType === EntityType.SETS) ||
        (nodesAreSelected && currentStylePanelType === EntityType.NODES) ||
        (edgesAreSelected && currentStylePanelType === EntityType.EDGES)
      ) {
        // current selection is still valid; do nothing
        return;
      }

      // make selection be the highest priority 'valid' thing
      if (setsAreSelected) {
        return dispatch(setStylePanelActiveType(EntityType.SETS));
      }
      if (nodesAreSelected) {
        return dispatch(setStylePanelActiveType(EntityType.NODES));
      }
      if (edgesAreSelected) {
        return dispatch(setStylePanelActiveType(EntityType.EDGES));
      }
    }
  };

export const selectExactly: ThunkActionFunc<
  [shapes?: UUID[], sets?: UUID[]],
  MetraSimpleAction<Pick<SelectedShapes, 'edgesSelected'>>
> =
  (shapes = [], sets = []) =>
  (dispatch, getState) => {
    dispatch(changeStylePanelToFollow({ shapes, sets }, false, false));
    dispatch({
      type: MODEL.UPDATE_SELECTION,
      payload: {
        shapes,
        sets,
      },
    });
    if (getState().modelReducer.base.isSearching) {
      dispatch(clearModelSearchResults());
      dispatch(setIsSearching(false));
    }
    const retv = dispatch(ensureSelectionConsistency());
    return retv;
  };

export const selectObjects: ThunkActionFunc<
  [objects?: UUID[], type?: 'shapes' | 'sets'],
  MetraSimpleAction<Pick<SelectedShapes, 'edgesSelected'>>
> =
  (objects = [], type = 'shapes') =>
  (dispatch) => {
    const sytlePanelArgs =
      type === 'shapes'
        ? { shapes: objects, sets: [] }
        : { shapes: [], sets: objects };
    dispatch(changeStylePanelToFollow(sytlePanelArgs, true, false));
    dispatch({
      type: MODEL.OBJECTS_SELECT,
      payload: {
        type,
        objects,
      },
    });
    return dispatch(ensureSelectionConsistency());
  };

export const pixiDestroyed: MetraActionFunc = () => ({
  type: MODEL.PIXI_DESTROYED,
});

/**
 * [pixiLoaded change pixi status to loaded]
 */
export const pixiLoaded: MetraActionFunc<[value: boolean], boolean> = (
  value
) => ({
  type: MODEL.PIXI_LOADED,
  payload: value,
});

/**
 * resizePixi resize the pixi canvas
 * @param size - new size
 * @return watcher size metra action
 */
export const resizePixi: ThunkActionFunc<
  [size: WatcherSize],
  MetraSimpleAction<WatcherSize>
> =
  (size) =>
  (d, _gs, { emit }) => {
    emit(GLOBAL_EVENT.PIXI_RESIZE, size);
    return d({
      type: MODEL.PIXI_RESIZE,
      payload: size,
    });
  };

export const pixiReady: ThunkActionFunc =
  () =>
  (dispatch, _gs, { emit }) => {
    emit(STAGE_EVENT.UPDATE.EVENTS);
    dispatch({
      type: MODEL.PIXI_READY,
    });
  };

/**
 * resizePixiComplete resize of pixi is completed
 */
export const resizePixiComplete: MetraActionFunc = () => ({
  type: MODEL.PIXI_RESIZE_COMPLETE,
});

/**
 * sets current model tool
 * @param tool - tool to set
 * @return a redux thunk
 */
export const setTool: ThunkActionFunc<
  [tool: string],
  void //MetraSimpleAction<string>
> = (tool) => {
  return (dispatch, _gs, { emit }) => {
    dispatch({
      type: MODEL.TOOL_SET,
      payload: tool,
    });
    emit(ENGINE.UPDATE.TOOL, tool);
  };
};

export const forceSetTool: MetraActionFunc<[tool: string], string> = (
  tool
) => ({
  type: MODEL.TOOL_SET,
  payload: tool,
});

export const updateModel: MetraActionFunc<
  [payload: Partial<BaseModelReducer>],
  Partial<BaseModelReducer>
> = (payload) => {
  if (payload.id != null) payload.id = payload.id.toString();

  return {
    type: MODEL.UPDATE_MODEL,
    payload,
  };
};

export const updateViewport: ThunkActionFunc<[any], MetraSimpleAction<any>> =
  (viewport) =>
  (dispatch, _gs, { emit }) => {
    emit(STAGE_EVENT.UPDATE.VIEWPORT, viewport);
    return dispatch({ type: MODEL.VIEWPORT_UPDATE, payload: viewport });
  };

export const showPropsPanel: MetraActionFunc = () => ({
  type: MODEL.SHOW_PROPS_PANEL,
});

export const hidePropsPanel: MetraActionFunc = () => ({
  type: MODEL.HIDE_PROPS_PANEL,
});

export const togglePropsPanel: MetraActionFunc = () => ({
  type: MODEL.TOGGLE_PROPS_PANEL,
});

export const setShowExitingSaveModal: MetraActionFunc = () => ({
  type: MODEL.SHOW_EXITING_SAVE_MODAL,
});

export const showImageFilesPanel: MetraActionFunc = () => ({
  type: MODEL.SHOW_FILES_PANEL,
});

export const hideFilesPanel: MetraActionFunc = () => ({
  type: MODEL.HIDE_FILES_PANEL,
});

export const toggleImageFilesPanel: MetraActionFunc = () => ({
  type: MODEL.TOGGLE_IMAGE_FILES_PANEL,
});

export const setStylePanelActiveType: MetraActionFunc<
  [type: string],
  string
> = (type) => ({
  type: MODEL.SET_STYLE_PANEL_ACTIVE_TYPE,
  payload: type,
});

export const showDeleteSetsConfirmationModal: MetraActionFunc = () => ({
  type: MODEL.SHOW_DEL_SET_CONFIRMATION_MODAL,
});

export const hideDeleteSetsConfirmationModal: MetraActionFunc = () => ({
  type: MODEL.HIDE_DEL_SET_CONFIRMATION_MODAL,
});

export const setReassigningEdgeId: MetraActionFunc<
  [edgeId: Option<UUID>],
  Option<UUID>
> = (edgeId) => ({
  type: MODEL.SET_REASSIGNING_EDGE_ID,
  payload: edgeId,
});

export const setShowNodeLabels: MetraActionFunc = () => ({
  type: MODEL.SET_SHOW_NODE_LABELS,
});

/**
 * @param  show
 * @returns
 */
export const setShowNameModal: MetraActionFunc<
  [show: boolean, selectionOnly: boolean],
  { show: boolean; selectionOnly: boolean }
> = (show, selectionOnly) => ({
  type: MODEL.SET_SHOW_NAME_MODAL,
  payload: { show, selectionOnly },
});

/**
 * @param  show
 * @returns
 */
export const setShowUnsavedChanges: MetraActionFunc<
  [show: boolean],
  boolean
> = (show) => ({
  type: MODEL.SET_SHOW_UNSAVED_CHANGES,
  payload: show,
});

export const setIsSearching: MetraActionFunc<
  [isSearching: boolean],
  boolean
> = (isSearching) => ({
  type: MODEL.SET_IS_SEARCHING,
  payload: isSearching,
});

export const setShowNoImageChangesModal = () =>
  showModal('NoImageChangesModal');

/**
 * ECS version of setShowNodeLabels.
 * Passes state explicitly, to keep Redux and ECS n'sync.
 * @param nodeLabelsVisibility
 */
export const setNodeLabelsVisibility: MetraActionFunc<
  [nodeLabelsVisibility: boolean],
  boolean
> = (nodeLabelsVisibility) => ({
  type: MODEL.SET_NODE_LABELS_VISIBILITY,
  payload: nodeLabelsVisibility,
});

export const setShowEdgeLabels: MetraActionFunc = () => ({
  type: MODEL.SET_SHOW_EDGE_LABELS,
});

/**
 * Controls visibility of the read-only warning flyout.
 * @param show
 * @param [anchorLocation]
 */
export const setShowReadOnlyWarningFlyout: MetraActionFunc<
  [show: boolean, anchorLocation?: PointLike],
  {
    show: boolean;
    anchorLocation: PointLike;
  }
> = (show, anchorLocation) => ({
  type: MODEL.SET_SHOW_READ_ONLY_WARNING_FLYOUT,
  payload: {
    show,
    anchorLocation: anchorLocation ? anchorLocation : { x: 0, y: 0 },
  },
});

/**
 * Controls visibility of the hover flyout.
 * @param show
 * @param text
 * @param [coordinates]
 */
export const setShowHoverFlyout: MetraActionFunc<
  [show: boolean, text: string, coordinates?: PointLike, style?: Object],
  {
    show: boolean;
    text: string;
    coordinates: PointLike;
    style: Object;
  }
> = (show, text, coordinates, style) => ({
  type: MODEL.SET_SHOW_HOVER_FLYOUT,
  payload: {
    show,
    text,
    coordinates: coordinates ? coordinates : { x: 0, y: 0 },
    style: style ? style : {},
  },
});

/**
 * ECS version of setShowEdgeLabels.
 * Passes state explicitly, to keep Redux and ECS n'sync.
 * @param edgeLabelsVisibility
 */
export const setEdgeLabelsVisibility: MetraActionFunc<[boolean], boolean> = (
  edgeLabelsVisibility
) => ({
  type: MODEL.SET_EDGE_LABELS_VISIBILITY,
  payload: edgeLabelsVisibility,
});

export const setIsOlderModel: MetraActionFunc<[boolean], boolean> = (
  isOlderModel
) => ({
  type: MODEL.SET_IS_OLDER_MODEL,
  payload: isOlderModel,
});

export const setAcknowledgeOlderModel: MetraActionFunc<[boolean], boolean> = (
  acknowledgedOlderModel
) => ({
  type: MODEL.SET_ACKNOWLEDGE_OLDER_MODEL,
  payload: acknowledgedOlderModel,
});

export const addHiddenSet: MetraActionFunc<[UUID], UUID> = (setId) => ({
  type: MODEL.HIDE_SET_COLOR,
  payload: setId,
});

export const removeHiddenSet: MetraActionFunc<[UUID], UUID> = (setId) => ({
  type: MODEL.SHOW_SET_COLOR,
  payload: setId,
});

export const showAllSetColors: MetraActionFunc = () => ({
  type: MODEL.SHOW_ALL_SET_COLORS,
});

export const hideSetColors: MetraActionFunc<[UUID[]], UUID[]> = (allSets) => ({
  type: MODEL.HIDE_ALL_SET_COLORS,
  payload: allSets,
});

export const toggleShowFilesView = (): MetraVoidAction => ({
  type: MODEL.TOGGLE_SHOW_FILES_VIEW,
});

/**
 *
 * @param lockStatus
 * @returns
 */
export const toggleLockBackground: ThunkActionFunc<
  [boolean],
  MetraSimpleAction<Boolean>
> =
  (lockStatus) =>
  (dispatch, _gs, { emit }) => {
    emit(GLOBAL_EVENT.TOGGLE_BACKGROUND_LOCK, !!lockStatus);
    return dispatch({
      type: MODEL.TOGGLE_LOCK_BACKGROUND,
      payload: !!lockStatus,
    });
  };

export const toggleMakeBackground = (): MetraVoidAction => ({
  type: MODEL.TOGGLE_MAKE_BACKGROUND,
});

export const toggleImageModal = (): MetraVoidAction => ({
  type: MODEL.TOGGLE_IMAGE_MODAL,
});

export const setReplaceNodeFile: MetraActionFunc<[unknown], unknown> = (
  file
) => ({
  type: MODEL.SET_REPLACE_NODE_FILE,
  payload: file,
});

export const changeAllSetsAlpha: MetraActionFunc<[number], number> = (
  alpha
) => ({
  type: MODEL.CHANGE_ALL_SETS_ALPHA,
  payload: alpha,
});

export const changeAllNodesAlpha: MetraActionFunc<[number], number> = (
  alpha
) => ({
  type: MODEL.CHANGE_ALL_NODES_ALPHA,
  payload: alpha,
});

export const changeAllEdgesAlpha: MetraActionFunc<[number], number> = (
  alpha
) => ({
  type: MODEL.CHANGE_ALL_EDGES_ALPHA,
  payload: alpha,
});

export const setMptGrabFocus: MetraActionFunc<[boolean], boolean> = (
  grabFocus
) => ({
  type: MODEL.FOCUS_MPT,
  payload: grabFocus,
});

export const UNIVERSAL_UNDO = 'universalUndo';
export const UNIVERSAL_REDO = 'universalUndo';

export const remember: ThunkActionFunc<[], void> = () => (dispatch) => {
  const payload = {
    undo: [
      {
        name: UNIVERSAL_UNDO,
        args: [],
      },
    ],
    redo: [
      {
        name: UNIVERSAL_REDO,
        args: [],
      },
    ],
  };

  dispatch({
    type: MODEL_HISTORY.CREATE,
    payload,
  });
};

/*
 * FOR DEVELOPMENT TOOLS
 */

export const setIsOnCanvas: MetraActionFunc<[boolean], boolean> = (
  isOnCanvas
) => ({
  type: MODEL.SET_IS_ON_CANVAS,
  payload: isOnCanvas,
});

/**
 * set the type of shape style
 * @param shapeStyle
 */
export const setShapeStyle: MetraActionFunc<[string], string> = (
  shapeStyle
) => ({
  type: MODEL.SHAPE_STYLE,
  payload: shapeStyle,
});

/**
 * set the type of arrowhead to use (for edges)
 * @param  arrowhead
 */
export const setArrowheadStyle: MetraActionFunc<[string], string> = (
  arrowhead
) => ({
  type: MODEL.ARROWHEAD_TYPE,
  payload: arrowhead,
});

/**
 * sets the content of the scripts tab
 * @param content
 */
export const setScriptsFileId: MetraActionFunc<[number], number> = (
  fileId
) => ({
  type: MODEL.SET_SCRIPTS_FILE_ID,
  payload: fileId,
});

/**
 * sets the content of the scripts tab
 * @param content
 */
export const setScriptsContent: MetraActionFunc<[string], string> = (
  content
) => ({
  type: MODEL.SET_SCRIPTS_CONTENT,
  payload: content,
});

/**
 * set the type of edge style
 * @param  waypointStyle
 */
export const setWaypointStyle: MetraActionFunc<
  [waypointStyle: number],
  number
> = (waypointStyle) => ({
  type: MODEL.WAYPOINT_STYLE,
  payload: waypointStyle,
});

export const isSearchBarFocused: MetraActionFunc<
  [focused: boolean],
  boolean
> = (focused) => ({
  type: MODEL.SEARCH_BAR_FOCUSED,
  payload: focused,
});

export const toggleMptGridPopout: MetraActionFunc = () => ({
  type: MODEL.TOGGLE_MPT_GRID_POPOUT,
});

export const closeMptGridPopout: MetraActionFunc = () => ({
  type: MODEL.CLOSE_MPT_GRID_POPOUT,
});

export const toggleDarkMode: MetraActionFunc = () => ({
  type: MODEL.TOGGLE_DARK_MODE,
});

export const toggleFilterImages: MetraActionFunc = () => ({
  type: MODEL.TOGGLE_FILTER_IMAGES,
});

export const toggleGridMenu: MetraActionFunc = () => ({
  type: MODEL.TOGGLE_GRID_MENU,
});

export const toggleDisplayFilterLabel: MetraActionFunc = () => ({
  type: MODEL.TOGGLE_FILTER_OVERLAY,
});

export const toggleDisplayFiltersFlyout: MetraActionFunc = () => ({
  type: MODEL.TOGGLE_FILTERS_FLYOUT,
});

export const setShowLockedModelsRefresh: MetraActionFunc<[boolean], boolean> = (
  show
) => ({
  type: MODEL.SET_SHOW_LOCKED_MODEL_REFRESH,
  payload: show,
});

export const setShowBlueLock: MetraActionFunc<[boolean], boolean> = (show) => ({
  type: MODEL.SET_SHOW_BLUE_LOCK,
  payload: show,
});

export const setShowRedLock: MetraActionFunc<[boolean], boolean> = (show) => ({
  type: MODEL.SET_SHOW_RED_LOCK,
  payload: show,
});
