import { cloneDeep } from 'lodash';
import {
  applyFilter,
  clearFilter,
  setSelectedFilter,
  setShowSaveFilterFlyout,
} from 'modules/model/table/utils';
import { Filter, ThunkAction, UUID } from 'types';
import { ACTIONS, ENGINE } from 'utils/constants-extra';
import {
  undoableAction,
  onUndo,
  onRedo,
} from 'modules/model/history/undoable-action';
import { issueSelectExactly } from './actions-helpers';
import {
  addSelectionFilter,
  deleteSelectionFilter,
  makeSelectionFilter,
} from 'modules/model/selectionFilter';
import { toggleDisplayFiltersFlyout } from 'modules/model/base/actions';

export const issueApplyFilter = undoableAction(function _issueApplyFilter(
  filterAlpha: number,
  filterIds: UUID[],
  filterSchemas: UUID[],
  filterUnpopulated: boolean
): ThunkAction<void> {
  return (dispatch, gs, { emit }) => {
    const oldFilterIds = cloneDeep(filterIds);
    const oldFilterSchemas = cloneDeep(filterSchemas);
    const model = gs().modelReducer;
    const oldSelectedShapes = model.base.selected.shapes;
    const oldSelectedSets = model.base.selected.sets;
    const oldSelection = oldSelectedShapes.concat(oldSelectedSets);
    const entities = dispatch(
      applyFilter(filterAlpha, filterIds, filterSchemas, filterUnpopulated)
    ).payload.entities;

    if (entities.length < 1) {
      // filter is now empty, there should be nothing to isolate on; so
      // unisolate everything.
      emit(ENGINE.UPDATE.SHAPES.UNISOLATE);
    }

    onUndo(
      issueClearFilter(oldSelection),
      issueSelectExactly(oldSelection, 'both', false)
    );
    onRedo(
      issueSelectExactly([], 'both', false),
      issueApplyFilter(
        filterAlpha,
        oldFilterIds,
        oldFilterSchemas,
        filterUnpopulated
      )
    );
  };
});

export const issueClearFilter = undoableAction(function _issueClearFilter(
  idsToSelect: UUID[] = []
): ThunkAction<void> {
  return (dispatch, gs, { emit }) => {
    const model = gs().modelReducer;
    const {
      alpha: oldAlpha,
      entities: oldEntityIds,
      hideUnpopulated: oldHideUnpopulated,
      schemas: oldSchemaIds,
    } = model.tableFilter;
    emit(ACTIONS.CLEAR_FILTER);
    dispatch(clearFilter());
    emit(ENGINE.UPDATE.SHAPES.UNISOLATE);

    onUndo(
      issueApplyFilter(
        oldAlpha,
        oldEntityIds,
        oldSchemaIds,
        oldHideUnpopulated
      ),
      issueSelectExactly([], 'both', false)
    );
    onRedo(
      issueClearFilter(idsToSelect),
      issueSelectExactly(idsToSelect, 'both', false)
    );
  };
});

export const issueDeleteFilter = undoableAction(function _issueDeleteFilter(
  filterId: UUID
): ThunkAction<void> {
  return (dispatch, gs, { emit }) => {
    const model = gs().modelReducer;
    const { filters } = model;
    const clonedFilters = cloneDeep(filters);
    const oldFilter = clonedFilters[filterId];
    const keys = Object.keys(clonedFilters);
    if (keys.includes(filterId))
      dispatch(deleteSelectionFilter(clonedFilters, filterId));
    dispatch(setShowSaveFilterFlyout(false));

    const { entities, filterUnpopulated, alpha, name, schemas, id } = oldFilter;
    onUndo(
      issueSaveFilter(entities, filterUnpopulated, name, schemas, alpha, id)
    );
    onRedo(issueDeleteFilter(id));
  };
});

export const issueSaveFilter = undoableAction(function _issueSaveFilter(
  entities: UUID[],
  filterUnpopulated: boolean,
  name: string,
  schemas: UUID[],
  alpha: number,
  filterId?: string
): ThunkAction<void> {
  return (dispatch, gs, { emit }) => {
    const filter = makeSelectionFilter(
      name,
      alpha,
      entities,
      filterUnpopulated,
      schemas,
      filterId
    );
    dispatch(addSelectionFilter(filter));
    dispatch(setShowSaveFilterFlyout(false));
    dispatch(setSelectedFilter(filter));
    dispatch(toggleDisplayFiltersFlyout());

    onUndo(issueDeleteFilter(filter.id));
    onRedo(
      issueSaveFilter(
        entities,
        filterUnpopulated,
        name,
        schemas,
        alpha,
        filter.id
      )
    );
  };
});

export const issueSelectFilter = undoableAction(function _issueSaveFilter(
  filter: Filter
): ThunkAction<void> {
  return (dispatch, gs, { emit }) => {
    // dispatch series of actions to select, apply, and toggle visibility of
    // the flyout when swapping/selecting saved filters
    const { alpha, entities, schemas, filterUnpopulated } = filter;
    const model = gs().modelReducer;
    const {
      alpha: oldAlpha,
      entities: oldEntityIds,
      hideUnpopulated: oldHideUnpopulated,
      schemas: oldSchemaIds,
    } = model.tableFilter;
    const { selectedFilter: oldSelectedFilter } = model.base;
    const someFilterApplied =
      oldEntityIds.length > 0 || oldSchemaIds.length > 0;

    dispatch(issueApplyFilter(alpha, entities, schemas, filterUnpopulated));
    dispatch(issueSetSelectedFilter(filter));
    dispatch(toggleDisplayFiltersFlyout());

    someFilterApplied
      ? onUndo(
          issueApplyFilter(
            oldAlpha,
            oldEntityIds,
            oldSchemaIds,
            oldHideUnpopulated
          )
        )
      : onUndo(issueClearFilter());
    onUndo(issueSetSelectedFilter(oldSelectedFilter));

    onRedo(issueApplyFilter(alpha, entities, schemas, filterUnpopulated));
    onRedo(issueSetSelectedFilter(filter));
  };
});

export const issueSetSelectedFilter = undoableAction(
  function _issueSetSelectedFilter(filter: Partial<Filter>): ThunkAction<void> {
    return (dispatch, gs, { emit }) => {
      // update redux state of selected filter
      const model = gs().modelReducer;
      const oldSelectedFilter = cloneDeep(model.base.selectedFilter);
      dispatch(setSelectedFilter(filter));

      onUndo(issueSetSelectedFilter(oldSelectedFilter));
      onRedo(issueSetSelectedFilter(filter));
    };
  }
);

export const issueShowSaveFilterFlyout = undoableAction(
  function _issueShowSaveFilterFlyout(show: boolean): ThunkAction<void> {
    return (dispatch, gs, { emit }) => {
      dispatch(setShowSaveFilterFlyout(show));

      onUndo(issueShowSaveFilterFlyout(!show));
      onRedo(issueShowSaveFilterFlyout(show));
    };
  }
);
