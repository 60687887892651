import { selectedNodeCount, selectedEdgeCount } from 'modules/model/selectors';
import { checkDefaultSelected } from 'modules/model/other';
import { MetraSimpleAction, SelectedShapes, ThunkAction } from 'types';
import { MODEL } from 'utils/constants';

/**
 * FIXME: this probably need to be refactored to be deterministic
 *        according to the current way we do selection
 * @returns a metra thunk action containing the selected shape info
 */
export function ensureSelectionConsistency(): ThunkAction<
  MetraSimpleAction<Pick<SelectedShapes, 'edgesSelected'>>
> {
  return (dispatch, getState) => {
    const {
      base: {
        selected: { shapes: selectedShapeIds },
      },
      shapes: allShapes,
    } = getState().modelReducer;

    dispatch({
      type: MODEL.OBJECTS_SELECTED_NODE_COUNT,
      payload: {
        nodesSelected: selectedNodeCount(allShapes, selectedShapeIds),
      },
    });
    dispatch({
      type: MODEL.OBJECTS_SELECTED_ONLY_DEFAULT_NODE,
      payload: {
        onlyDefaultNodesSelected: checkDefaultSelected(
          allShapes,
          selectedShapeIds
        ),
      },
    });
    return dispatch({
      type: MODEL.OBJECTS_SELECTED_EDGE_COUNT,
      payload: {
        edgesSelected: selectedEdgeCount(allShapes, selectedShapeIds),
      },
    });
  };
}
