import {
  issueChangeAllEdgesAlpha,
  issueChangeAllNodesAlpha,
  issueChangeAllSetsAlpha,
  issueChangeEdgeAlpha,
  issueChangeNodeAlpha,
  issueChangeSetAlpha,
  issueDeselect,
  issueDeselectAll,
  issueGetSelected,
  issueResetEdgeAlphas,
  issueResetNodeAlphas,
  issueResetSetAlphas,
  issueSelect,
  issueSelectAll,
  issueSelectExactly,
} from './actions-helpers';
import type { ModelEngine } from 'engine/engine';
import type { Entity } from 'ecs/Entity';
import {
  ActionsConstructor,
  ActionSelectType,
  EventUnsubFunc,
  GridSettingsReducer,
} from 'types';
import { dispatcher } from 'utils/utils-extra';
import { ACTIONS, ENGINE } from 'utils/constants-extra';
import { ECS_TAG, MIN_GRID_SIZE } from 'utils/constants';
import { t3dev } from 't3dev';
import {
  setGridSettings,
  toggleDisplayGrid,
  toggleSnapToGrid,
} from 'modules/model/gridSettings';
import { remember } from 'modules/model/base/actions';
import { issueApplyFilter, issueClearFilter } from './filter';

export class DefaultActions {
  static _engine: Option<ModelEngine> = null;

  static _multiSelect: Option<Entity> = null;

  static _unsubs: EventUnsubFunc[] = [];

  /**
   * Set the alpha (visibility) for the set
   * @param [id] - The ID of the set to change
   * @param [alpha] - The alpha value (between 0 and 1, inclusive)
   **/
  static changeSetAlpha(id: string, alpha: number) {
    dispatcher(issueChangeSetAlpha(id, alpha));
  }

  /**
   * Set the width and height of the grid rows and grid columns
   * @param settings - an object with a width and/or height properties
   */
  static updateGridSettings(settings: Partial<GridSettingsReducer>) {
    if (settings) {
      dispatcher(setGridSettings(settings));

      const { width, height } = settings;

      if (width && width >= MIN_GRID_SIZE) {
        dispatcher(remember());
      }

      if (height && height >= MIN_GRID_SIZE) {
        dispatcher(remember());
      }
    }
  }

  /**
   * toggle the display of the grid
   */
  static toggleDisplayGrid() {
    dispatcher(toggleDisplayGrid());
  }

  /**
   * toggle the snap to grid
   */
  static toggleSnapToGrid() {
    dispatcher(toggleSnapToGrid());
  }

  /**
   * Reset all set alphas to the same value (default 1)
   * @param [alpha] - The alpha value to which all sets will be reset
   * (between 0 and 1, inclusive)
   **/
  static resetSetAlphas(alpha = 1) {
    dispatcher(issueResetSetAlphas(alpha));
  }

  /**
   * Changes the "All Sets" alpha, which is an alpha value
   * applied multiplicatively to each set when rendering.
   * This value is stored separately and does not affect the
   * individual alphas of each set.
   * @param [alpha] - The alpha value
   **/
  static changeAllSetsAlpha(alpha = 1) {
    dispatcher(issueChangeAllSetsAlpha(alpha));
  }

  /**
   * Set the alpha (visibility) for the node
   * @param id - The ID of the node to change
   * @param alpha - The alpha value (between 0 and 1, inclusive)
   **/
  static changeNodeAlpha(id: string, alpha: number) {
    dispatcher(issueChangeNodeAlpha(id, alpha));
  }

  /**
   * Reset all node alphas to the same value (default 1)
   * @param [alpha] - The alpha value to which all nodes will be reset
   * (between 0 and 1, inclusive)
   **/
  static resetNodeAlphas(alpha = 1) {
    dispatcher(issueResetNodeAlphas(alpha));
  }

  /**
   * Changes the "All Nodes" alpha, which is an alpha value
   * applied multiplicatively to each node when rendering.
   * This value is stored separately and does not affect the
   * individual alphas of each node.
   * @param [alpha] - The alpha value
   **/
  static changeAllNodesAlpha(alpha = 1) {
    dispatcher(issueChangeAllNodesAlpha(alpha));
  }

  /**
   * Set the alpha (visibility) for the edge
   * @param id - The ID of the edge to change
   * @param alpha - The alpha value (between 0 and 1, inclusive)
   **/
  static changeEdgeAlpha(id: string, alpha: number) {
    dispatcher(issueChangeEdgeAlpha(id, alpha));
  }

  /**
   * Reset all edge alphas to the same value (default 1)
   * @param [alpha] - The alpha value to which all edges will be reset
   * (between 0 and 1, inclusive)
   **/
  static resetEdgeAlphas(alpha = 1) {
    dispatcher(issueResetEdgeAlphas(alpha));
  }

  /**
   * Changes the "All Edges" alpha, which is an alpha value
   * applied multiplicatively to each edge when rendering.
   * This value is stored separately and does not affect the
   * individual alphas of each edge.
   * @param [alpha] - The alpha value
   **/
  static changeAllEdgesAlpha(alpha = 1) {
    dispatcher(issueChangeAllEdgesAlpha(alpha));
  }

  getActions() {
    return ACTIONS;
  }

  static getMultiSelect() {
    return DefaultActions.multiSelect;
  }

  /**
   * Filter the MPT and isolate filtered shapes in the graph
   **/
  static applyFilter(
    filterAlpha: number,
    filterIds: UUID[],
    filterSchemas: UUID[],
    filterUnpopulated: boolean,
    shouldCreateHistory?: boolean
  ) {
    const action = shouldCreateHistory
      ? issueApplyFilter.undoable
      : issueApplyFilter;
    dispatcher(
      action(filterAlpha, filterIds, filterSchemas, filterUnpopulated)
    );
  }

  /**
   * Clear any existing filter and remove shape isolation
   * @param [idsToSelect] - Array of set and shape Ids to select when undoing this clear
   * @param [shouldCreateHistory] - True if we should create an undo/redo history entry for this action
   */
  static clearFilter(idsToSelect: UUID[], shouldCreateHistory?: boolean) {
    const action = shouldCreateHistory
      ? issueClearFilter.undoable
      : issueClearFilter;
    dispatcher(action(idsToSelect));
  }

  /**
   * Add objects to the current selection. Valid types of selection:
   * shapes - graph shapes such as nodes, edges and backgrounds
   * sets - entities representing collections of nodes and edges
   * @param ids - The objects to add to the selection
   * @param [type=shapes] - The type of selection
   * @param [table] - select table [default: false]
   **/
  static select(
    ids: UUID[],
    type: ActionSelectType = 'shapes',
    table = false,
    shouldCreateHistory = true
  ) {
    const action = shouldCreateHistory ? issueSelect.undoable : issueSelect;
    dispatcher(action(ids, type, table));
  }

  /**
   * Select all objects of all types
   * shapes - graph shapes such as nodes, edges and backgrounds
   * @param ids - The objects to add to the selection
   * @param [type=shapes] - The type of selection
   * @param [table]
   **/
  static selectAll(
    ids: UUID[],
    type: 'shapes' | 'sets' = 'shapes',
    table = false
  ) {
    dispatcher(issueSelectAll(ids, type, table));
  }

  /**
   * Establishes objects as the current selection, replacing the current
   * selection. Valid types of selection:
   * shapes - graph shapes such as nodes, edges and backgrounds
   * sets - entities representing collections of nodes and edges
   * @param ids - The objects to establish to the selection
   * @param [type=shapes] - The type of selection
   * @param [table] - select table [default: false]
   **/
  static selectExactly(
    ids: UUID[],
    type: ActionSelectType = 'both',
    table = false,
    shouldCreateHistory = true
  ) {
    if (shouldCreateHistory) {
      dispatcher(issueSelectExactly.undoable(ids, type, table));
    } else {
      dispatcher(issueSelectExactly(ids, type, table));
    }
  }

  /**
   * NOTE: note sure this is ever used
   * Remove objects from the current selection.
   * @param [ids] - The objects to remove from the selection
   * @param [type=shapes] - The type of selection
   **/
  static deselect(ids: UUID[], type: ActionSelectType = 'shapes') {
    dispatcher(issueDeselect(ids, type));
  }

  /**
   * Deselect all objects of all types.
   **/
  static deselectAll() {
    dispatcher(issueDeselectAll());
  }

  static stageZoomIn() {
    dispatcher((_d, _gs, { emit }) => {
      emit(ENGINE.CAMERA.ZOOM.IN);
    });
  }

  static stageZoomOut() {
    dispatcher((_d, _gs, { emit }) => {
      emit(ENGINE.CAMERA.ZOOM.OUT);
    });
  }

  static stageZoomTo(value: Numberish) {
    dispatcher((_d, _gs, { emit }) => {
      emit(ENGINE.CAMERA.ZOOM.SET, value);
    });
  }

  static stageZoomReset() {
    dispatcher((_d, _gs, { emit }) => {
      emit(ENGINE.CAMERA.ZOOM.FIT);
    });
  }

  /**
   * toggle the collapse of a set, based on its uuid
   * @param id
   * @modifies emits action/TOGGLE_COLLAPSE_SET event
   */
  static toggleCollapseSet(id: UUID) {
    dispatcher((_d, _gs, { emit }) => {
      emit(ACTIONS.TOGGLE_COLLAPSE_SET, id);
    });
  }

  /**
   * toggle the collapse of multiple sets,
   * based on which sets are selected in the table and model
   * @modifies emits action/TOGGLE_COLLAPSE_SETS event
   */
  static toggleCollapseSets() {
    dispatcher((_d, _gs, { emit }) => {
      emit(ACTIONS.TOGGLE_COLLAPSE_SETS);
    });
  }

  /**
   * Get the selected objects of given type. A shortcut for accessing redux state directly.
   * @param {string} [type=shapes] - The type of selection
   * @returns {array}
   */
  static selected(type: ActionSelectType = 'shapes'): UUID[] {
    return dispatcher(issueGetSelected(type));
  }

  static _currentInstance: Option<DefaultActions> = null;

  /**
   * Create a new instance of Actions. Takes the entries in `Actions.actions`
   * and builds the necessary constants, event listeners, etc to create a
   * proper EventManager event for the given action, as well as a static
   * helper for emitting that event.
   */
  constructor() {
    DefaultActions._unsubs = [];

    t3dev().setValue('actions', DefaultActions);

    // Object.forEach(this as unknown as Record<string, EventManagerListener<any[], any>> , ([property, callback]) => {
    // Object.getOwnPropertyNames(DefaultActions).forEach(property => {
    //   const descriptor = Object.getOwnPropertyDescriptor(DefaultActions, property);

    //   if(!descriptor?.value || !descriptor.enumerable) return;
    //   const callback = descriptor.value;
    //   console.log('a:c::', {descriptor, callback});
    //   if (typeof callback !== 'function') return;
    //   const screaming = camelToScreamingSnake(property);
    //   ACTIONS[screaming] = `${ACTIONS_PREFIX}/${screaming}`;

    //   DefaultActions._unsubs.push(
    //     EventManager.on(
    //       ACTIONS[screaming],
    //       callback.bind(DefaultActions),
    //       DefaultActions
    //     )
    //   );

    //   Object.defineProperty(DefaultActions, property, {
    //     value: (...args: any[]) => {
    //       return EventManager.emit(ACTIONS[screaming], ...args);
    //     },
    //   });

    //   // Actions[property] = (...args) => {
    //   //   return EventManager.emit(ACTIONS[screaming], ...args);
    //   // };
    // });
    DefaultActions._currentInstance = this;
  }

  /**
   * set current engine instance
   * @param value
   */
  static set engine(value: Option<ModelEngine>) {
    DefaultActions._engine = value;
  }

  /**
   * @returns current engine instance, if it exists
   */
  static get engine(): Option<ModelEngine> {
    return DefaultActions._engine;
  }

  /**
   * @returns current multiselect instance
   */
  static get multiSelect(): Option<Entity> {
    return DefaultActions.engine?.ecs.tagManager.getEntityByTag(
      ECS_TAG.MULTI_SELECT
    );
  }

  /**
   * Detach event listeners from this instance. Always call when you're done with Actions!
   */
  detach() {
    DefaultActions._unsubs.forEach((unsub) => unsub());
  }
}

// HACK: enables us to fully type Actions
//       I don't like it, but it "works"
export const Actions = DefaultActions as unknown as ActionsConstructor;

export default Actions;
