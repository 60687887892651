import { makeRange } from 'utils/utils';
import { hydrateModel } from '../actions';
import { gid } from '../gid';
import {
  _addColumns,
  _addRows,
  _deleteSheet,
  _renameSheet,
  _replaceSheetColumns,
  _replaceSheetRows,
  _replaceSheetSelectionState,
  addSheetSelection,
  clearAllSheetsSelection,
  clearSheetSelection,
  createSheet,
  createSheetConfig,
  deleteColumns,
  deleteRows,
  extendSheetSelection,
  extrudeSheetSelection,
  removeColumns,
  removeSheetColumnSelection,
  removeSheetRowSelection,
  removeSheetSelection,
  replaceSheetSelection,
  setActiveSheet,
  setColumnRowSize,
  setError,
  setEvaluated,
  setSheetResizingRowColumnIDs,
  setValue,
} from './actions';
import { createReducer } from '@reduxjs/toolkit';
import { isEmpty } from 'utils/utils-extra';
import { isSome } from 'helpers/utils';
import type { CellKey, SheetSelections, SheetsReducerState } from './types';

export const initialState: SheetsReducerState = {
  ids: [],
  byId: {},
  selected: {},
  active: null,
  config: {
    sheetIds: [],
    bySheetId: {},
  },
};

export const reducer = createReducer(initialState, (builder) => {
  // --- HYDRATION
  builder.addCase(hydrateModel, (state, { payload: model }) => {
    if (!model.sheets) return;

    state.byId = { ...model.sheets.byId };
    state.ids = [...model.sheets.ids];
  });

  // --- SELECTION
  builder.addCase(addSheetSelection, (state, { payload }) => {
    const sheetConfig = state.config.bySheetId[payload.sheet];

    if (sheetConfig) {
      // disable selection when the cell is resizing
      if (
        isSome(sheetConfig.ui.resizingColumnIndex) &&
        isSome(sheetConfig.ui.resizingRowIndex)
      ) {
        return;
      }
    }

    const key = [payload.row, payload.column] as CellKey;

    // create selection object for this sheet if it doesn't have one yet
    const selected = state.selected[payload.sheet] || { ranges: [], byId: {} };

    // add range
    selected.ranges.push([key]);

    // update byId cache
    selected.byId[payload.row] ||= {};
    selected.byId[payload.row][payload.column] = true;

    // save changes back to state
    state.selected[payload.sheet] = selected;
  });

  builder.addCase(replaceSheetSelection, (state, { payload }) => {
    const sheetConfig = state.config.bySheetId[payload.sheet];

    if (sheetConfig) {
      // disable selection when the cell is resizing
      if (
        isSome(sheetConfig.ui.resizingColumnIndex) &&
        isSome(sheetConfig.ui.resizingRowIndex)
      ) {
        return;
      }
    }

    const key = [payload.row, payload.column] as CellKey;

    // replace old selection
    const selected: SheetSelections = {
      ranges: [[key]],
      byId: { [payload.row]: { [payload.column]: true } },
    };

    // save changes back to state
    state.selected[payload.sheet] = selected;
  });

  builder.addCase(_replaceSheetSelectionState, (state, { payload }) => {
    state.selected[payload.sheet] = payload.sheetSelections;
  });

  builder.addCase(clearAllSheetsSelection, (state) => {
    state.selected = {};
  });

  builder.addCase(clearSheetSelection, (state, { payload }) => {
    // clear old selection
    const selected: SheetSelections = {
      ranges: [],
      byId: {},
    };

    // save changes back to state
    state.selected[payload.sheet] = selected;
  });

  builder.addCase(removeSheetSelection, (state, { payload }) => {
    // create selection object for this sheet if it doesn't have one yet
    const selected = state.selected[payload.sheet] || { ranges: [], byId: {} };

    // if the cell is not selected, ignore it
    if (!selected.byId[payload.row]?.[payload.column]) {
      return;
    }

    delete selected.byId[payload.row][payload.column];
    if (isEmpty(selected.byId[payload.row])) {
      delete selected.byId[payload.row];
    }

    // checking every array for matches already takes N time
    // so we just rebuild the array as we check
    const ranges = [];
    for (const range of selected.ranges) {
      const newRange = [];

      // reinsert other entries
      for (const [r, c] of range) {
        if (r !== payload.row || c !== payload.column) {
          newRange.push([r, c] as CellKey);
        }
      }

      // reinsert this range if it still has cells
      if (newRange.length > 0) {
        ranges.push(newRange);
      }
    }

    selected.ranges = ranges;

    // save changes back to state
    state.selected[payload.sheet] = selected;
  });

  builder.addCase(removeSheetColumnSelection, (state, { payload }) => {
    // get the selection object for this sheet
    const selected = state.selected[payload.sheet];

    // if theres is nothing in the sheet selected, ignore it
    if (!selected) {
      return;
    }

    // if the cell is not selected, ignore it
    if (!selected.byId[payload.row]?.[payload.column]) {
      return;
    }

    // remove the column selection
    for (const range of selected.ranges) {
      for (const [r, c] of range) {
        if (c === payload.column) {
          delete selected.byId[r][c];

          if (isEmpty(selected.byId[r])) {
            delete selected.byId[r];
          }
        }
      }
    }

    // update the selected ranges
    selected.ranges = selected.ranges.filter((range) => {
      return range.some(([r, c]) => c !== payload.column);
    });
  });

  builder.addCase(removeSheetRowSelection, (state, { payload }) => {
    // get the selection object for this sheet
    const selected = state.selected[payload.sheet];

    // if theres is nothing in the sheet selected, ignore it
    if (!selected) {
      return;
    }

    // if the cell is not selected, ignore it
    if (!selected.byId[payload.row]?.[payload.column]) {
      return;
    }

    // remove the row selection
    for (const range of selected.ranges) {
      for (const [r, c] of range) {
        if (r === payload.row) {
          delete selected.byId[r][c];

          if (isEmpty(selected.byId[r])) {
            delete selected.byId[r];
          }
        }
      }
    }

    // update the selected ranges
    selected.ranges = selected.ranges.filter((range) => {
      return range.some(([r, c]) => r !== payload.row);
    });
  });

  builder.addCase(extendSheetSelection, (state, { payload }) => {
    const sheetConfig = state.config.bySheetId[payload.sheet];

    if (sheetConfig) {
      // disable selection when the cell is resizing
      if (
        isSome(sheetConfig.ui.resizingColumnIndex) &&
        isSome(sheetConfig.ui.resizingRowIndex)
      ) {
        return;
      }
    }

    const key = [payload.row, payload.column] as CellKey;

    // create selection object for this sheet if it doesn't have one yet
    const selected = state.selected[payload.sheet] || { ranges: [], byId: {} };

    // NOTE: for now, if there is no selection, we set this as the first selection
    // TBD whether we want to do this at the reducer level, or whether it's better
    // for different sheets to implement this behavior differently
    const current = selected.ranges[selected.ranges.length - 1];
    if (!current) {
      // same as addSheetSelection()
      selected.ranges.push([key]);
      selected.byId[payload.row] ||= {};
      selected.byId[payload.row][payload.column] = true;
      state.selected[payload.sheet] = selected;
      return;
    }

    // the REAL extend behavior starts here
    const anchor = current[0];
    const sheet = state.byId[payload.sheet];

    // delete old ranges in case user is shortening their selection
    for (const key of current) {
      delete selected.byId[key[0]]?.[key[1]];
      if (isEmpty(selected.byId[key[0]])) {
        delete selected.byId[key[0]];
      }
    }

    // get the range coordinates, sorted so we don't have to iterate backwards
    const range = [];

    // makeRange should preserve order so that anchor is always first
    const xStart = sheet.rows.indexOf(anchor[0]);
    const xEnd = sheet.rows.indexOf(key[0]);
    const rangeX = makeRange(xStart, xEnd, true);

    const yStart = sheet.columns.indexOf(anchor[1]);
    const yEnd = sheet.columns.indexOf(key[1]);
    const rangeY = makeRange(yStart, yEnd, true);
    for (const x of rangeX) {
      for (const y of rangeY) {
        // insert all the cells within this range
        const key = [sheet.rows[x], sheet.columns[y]];
        range.push(key as CellKey);
        selected.byId[key[0]] ||= {};
        selected.byId[key[0]][key[1]] = true;
      }
    }

    // overwrite the previous range with the newly extended one
    selected.ranges[selected.ranges.length - 1] = range;

    // save changes back to state
    state.selected[payload.sheet] = selected;
  });

  builder.addCase(extrudeSheetSelection, (state, { payload }) => {
    const sheetConfig = state.config.bySheetId[payload.sheet];

    if (sheetConfig) {
      // disable selection when the cell is resizing
      if (
        isSome(sheetConfig.ui.resizingColumnIndex) &&
        isSome(sheetConfig.ui.resizingRowIndex)
      ) {
        return;
      }
    }

    const key = [payload.row, payload.column] as CellKey;

    // create selection object for this sheet if it doesn't have one yet
    const selected = state.selected[payload.sheet] || { ranges: [], byId: {} };

    // NOTE: for now, if there is no selection, we set this as the first selection
    // TBD whether we want to do this at the reducer level, or whether it's better
    // for different sheets to implement this behavior differently
    const current = selected.ranges[selected.ranges.length - 1];
    if (!current) {
      // same as addSheetSelection()
      selected.ranges.push([key]);
      selected.byId[payload.row] ||= {};
      selected.byId[payload.row][payload.column] = true;
      state.selected[payload.sheet] = selected;
      return;
    }

    const anchor = current[0];

    // If the user clicked the current anchor as their destination
    // Extrude does not "go" anywhere, so this is a no-op
    if (anchor[0] === key[0] && anchor[1] === key[1]) {
      return;
    }

    // the REAL extrude behavior starts here
    const sheet = state.byId[payload.sheet];

    // IN EXTRUSION WE DO NOT DELETE OLD KEYS, the range only grows!

    // get the range coordinates, sorted so we don't have to iterate backwards
    const range = [];

    // makeRange should preserve order so that anchor is always first
    // we reverse the order to make the END of the range the new anchor
    // that's what makes extruding different from extending!
    const xStart = sheet.rows.indexOf(anchor[0]);
    const xEnd = sheet.rows.indexOf(key[0]);
    const rangeX = makeRange(xStart, xEnd, true).reverse();

    const yStart = sheet.columns.indexOf(anchor[1]);
    const yEnd = sheet.columns.indexOf(key[1]);
    const rangeY = makeRange(yStart, yEnd, true).reverse();
    // insert all the cells within this range
    for (const x of rangeX) {
      for (const y of rangeY) {
        const key = [sheet.rows[x], sheet.columns[y]];

        // ignore the anchor, it's already part of previous range
        if (key[0] === anchor[0] && key[1] === anchor[1]) continue;
        range.push(key as CellKey);
        selected.byId[key[0]] ||= {};
        selected.byId[key[0]][key[1]] = true;
      }
    }

    // extrusion creates a new range
    selected.ranges.push(range);

    // save changes back to state
    state.selected[payload.sheet] = selected;
  });

  builder.addCase(setValue, (state, { payload }) => {
    const sheet = state.byId[payload.sheet];

    sheet.entries[payload.row] ||= {};
    const valId = sheet.entries[payload.row][payload.column] || gid();

    sheet.entries[payload.row][payload.column] = valId;
    sheet.values[valId] = { raw: payload.value };
  });

  builder.addCase(setEvaluated, (state, { payload }) => {
    const sheet = state.byId[payload.sheet];
    const value = sheet.values[payload.valueId];
    value.evaluated = payload.evaluated;
    delete value.error;
  });

  builder.addCase(setError, (state, { payload }) => {
    const value = state.byId[payload.sheet].values[payload.valueId];
    value.error = payload.error;
    delete value.evaluated;
  });

  builder.addCase(_addRows, (state, action) => {
    const sheet = state.byId[action.payload.sheet];
    sheet.rows.push(...action.payload.rows);
  });

  builder.addCase(_addColumns, (state, action) => {
    const sheet = state.byId[action.payload.sheet];
    sheet.columns.push(...action.payload.columns);
  });

  builder.addCase(deleteColumns, (state, action) => {
    const sheet = state.byId[action.payload.sheet];
    sheet.columns = sheet.columns.filter((col) => {
      return !action.payload.columns.includes(col);
    });
  });

  builder.addCase(_replaceSheetColumns, (state, action) => {
    const sheet = state.byId[action.payload.sheet];
    sheet.columns = action.payload.columns;
  });

  builder.addCase(deleteRows, (state, action) => {
    const sheet = state.byId[action.payload.sheet];
    sheet.rows = sheet.rows.filter((row) => {
      return !action.payload.rows.includes(row);
    });
  });

  builder.addCase(_replaceSheetRows, (state, action) => {
    const sheet = state.byId[action.payload.sheet];
    sheet.rows = action.payload.rows;
  });

  builder.addCase(removeColumns, (state, action) => {
    const sheet = state.byId[action.payload.sheet];

    // make a map for O(n) deletion
    const toDelete: Record<string, true> = {};
    for (const column of action.payload.columns) {
      toDelete[column] = true;
    }

    // filter out any deleted columns
    sheet.columns = sheet.columns.filter((c) => !toDelete[c]);

    for (const row in sheet.entries) {
      const cols = sheet.entries[row];

      for (const col in cols) {
        // delete any removed column and its values
        if (toDelete[col]) {
          const valId = cols[col];
          delete cols[col];
          delete sheet.values[valId];
        }
      }

      // if the row has no columns left, delete the whole entry
      if (isEmpty(cols)) {
        delete sheet.entries[row];
      }
    }
  });

  builder.addCase(createSheet, (state, action) => {
    const id = gid();
    const sheet = {
      name: action.payload.name,
      type: action.payload.type,
      rows: [],
      columns: [],
      entries: {},
      values: {},
    };
    state.ids.push(id);
    state.byId[id] = sheet;
  });

  builder.addCase(_renameSheet, (state, action) => {
    const sheet = state.byId[action.payload.sheet];
    sheet.name = action.payload.name;
  });

  builder.addCase(_deleteSheet, (state, action) => {
    delete state.byId[action.payload.sheet];
    state.ids = state.ids.filter((id) => id !== action.payload.sheet);
    state.active = null;
  });

  builder.addCase(setActiveSheet, (state, action) => {
    state.active = action.payload.sheet;
  });

  // sheet configuration

  builder.addCase(createSheetConfig, (state, action) => {
    const { sheetID } = action.payload;

    state.config.sheetIds = Array.from(
      new Set([...state.config.sheetIds, sheetID])
    );

    state.config.bySheetId[sheetID] = {
      ui: {
        resizingColumnIndex: null,
        resizingRowIndex: null,
      },
      columnIndexes: [],
      byColumnIndex: {},
      byRowIndex: {},
      rowIndexes: [],
    };
  });

  builder.addCase(setColumnRowSize, (state, action) => {
    const {
      payload: { rowIndex, sheetID, size, columnIndex },
    } = action;

    const sheetConfig = state.config.bySheetId[sheetID];
    const [columnWidth, rowHeight] = size;

    sheetConfig.columnIndexes = Array.from(
      new Set([...sheetConfig.columnIndexes, columnIndex])
    );
    sheetConfig.byColumnIndex[columnIndex] = {
      index: columnIndex,
      width: columnWidth,
    };
    sheetConfig.rowIndexes = Array.from(
      new Set([...sheetConfig.rowIndexes, rowIndex])
    );
    sheetConfig.byRowIndex[rowIndex] = {
      index: rowIndex,
      height: rowHeight,
    };
  });

  builder.addCase(setSheetResizingRowColumnIDs, (state, action) => {
    const {
      payload: { sheetID, columnIndex, rowIndex },
    } = action;

    const sheetConfig = state.config.bySheetId[sheetID];
    sheetConfig.ui.resizingColumnIndex = columnIndex;
    sheetConfig.ui.resizingRowIndex = rowIndex;
  });
});
