import { BaseModelReducer } from 'types';
import { METRA, SELECTION_EMPHASIS, TOOL } from 'utils/constants';

export const initialBaseModelState: BaseModelReducer = {
  acknowledgedOlderModel: false,
  allEdgesAlpha: 1,
  allNodesAlpha: 1,
  allSetsAlpha: 1,
  arrowheadStyle: 'mixed',
  collapsed: {
    shapes: {},
    sets: {},
  },
  contextMenu: {
    show: false,
    position: { x: 0, y: 0 },
  },
  copying: false,
  cutting: false,
  darkMode: false,
  drawingFillAlpha: 1,
  drawingFillColor: '#ffffff',
  drawingMode: 'Line',
  drawingStrokeAlpha: 1,
  drawingStrokeColor: '#000000',
  drawingStrokeWidth: 2,
  edgeNodes: [],
  edgesSelected: 0,
  error: false,
  filterAlpha: 0.15,
  filterImages: true,
  filterOverlay: true,
  hiddenSetColors: [],
  hoverFlyout: {
    show: false,
    text: '',
    coordinates: { x: 0, y: 0 },
    style: {},
  },
  id: null,
  incompatible: false,
  initialized: false,
  isLoading: false,
  isOlderModel: false,
  isOnCanvas: false,
  isProtected: false,
  isReadOnly: false,
  isSearching: false,
  isVerifyingModelVersion: false,
  labelSize: 14,
  lockBackground: true,
  makeBackground: false,
  menuLaunchLocation: null,
  modelLibraryPath: 'Files/',
  modelParentFolder: null,
  modelVersionId: null,
  mouseLocation: null,
  mptGrabFocus: false,
  name: '',
  nodeDefaultLayout: 'Rings',
  nodeMediaFile: null,
  nodesSelected: 0,
  notFound: false,
  onlyDefaultNodesSelected: false,
  pasting: false,
  pendingEdge: null,
  pixiLoaded: false,
  pixiReady: false,
  projectId: undefined,
  recentColors: [],
  readOnlyWarningFlyout: {
    show: false,
    anchorLocation: { x: 0, y: 0 },
  },
  showImageFilesPanel: false,
  showFilesView: false,
  reassigningEdgeId: null,
  saveSelectionOnly: false,
  scriptsContent: null,
  scriptsFileId: null,
  selected: { shapes: [], sets: [] },
  selectedFilter: {
    id: '-1',
    name: 'Filter Views',
    entities: [],
    filterUnpopulated: false,
    schemas: [],
  },
  selectionEmphasis: SELECTION_EMPHASIS,
  shapeStyle: 'mixed',
  showDeleteSetsConfirmationModal: false,
  showEdgeLabels: true,
  showExitingSaveModal: false,
  showFiltersFlyout: false,
  showGridMenu: false,
  showImageModal: false,
  showMptGridPopout: false,
  showNameModal: false,
  showNodeLabels: true,
  showPropsPanel: false,
  showSaveFilterFlyout: false,
  showStylePanel: false,
  showUnsavedChanges: false,
  showBlueLock: false,
  showRedLock: false,
  showLockModelRefresh: false,
  stylePanelActiveType: 'Nodes',
  tableItems: {
    entities: {
      nodes: [],
      edges: [],
      sets: [],
      modelProps: [],
      modelCalcs: [],
      paths: [],
    },
    schemas: {
      nodes: [],
      edges: [],
      sets: [],
      modelProps: [],
      modelCalcs: [],
      paths: [],
    },
  },
  textAlpha: 1,
  textStrokeAlpha: 1,
  textStrokeColor: '#ffffff',
  textStrokeThickness: 0,
  textColor: '#000000',
  textFontSize: 16,
  tool: TOOL.SELECT,
  validClipboardCellRangeType: null,
  viewport: {
    height: 0,
    width: 0,
  },
  version: METRA.VERSION,
  waypointStyle: -1,
  searchBarFocused: false,
};
