import { RootReducer } from 'types';

export function getBase(state: RootReducer) {
  return state.modelReducer.base;
}

export function getSelectedFilter(state: RootReducer) {
  return state.modelReducer.base.selectedFilter;
}

export function getShowExitingSaveModal(state: RootReducer) {
  return state.modelReducer.base.showExitingSaveModal;
}

export function getShowFilterMenuFlyout(state: RootReducer) {
  return state.modelReducer.base.showFiltersFlyout;
}

export function getShowSaveFilterFlyout(state: RootReducer) {
  return state.modelReducer.base.showSaveFilterFlyout;
}

export function getIsModelReadonly(state: RootReducer) {
  return state.modelReducer.base.isReadOnly;
}

export function getIsVerifyingModelVersion(state: RootReducer) {
  return state.modelReducer.base.isVerifyingModelVersion;
}

export function getIsModelLoading(state: RootReducer) {
  return state.modelReducer.base.isLoading;
}

export function getIsModelInitialized(state: RootReducer) {
  return state.modelReducer.base.initialized;
}

export function getModelId(state: RootReducer) {
  return state.modelReducer.base.id;
}

export function getModelProjectId(state: RootReducer) {
  return state.modelReducer.base.projectId;
}

export function getViewport(state: RootReducer) {
  return state.modelReducer.base.viewport;
}

export function getSelectedSets(state: RootReducer) {
  return state.modelReducer.base.selected.sets;
}

export function getSelectedShapes(state: RootReducer) {
  return state.modelReducer.base.selected.shapes;
}

export function getShowModelFilesView(state: RootReducer) {
  return state.modelReducer?.base?.showFilesView;
}

export function getModelViewport(state: RootReducer) {
  return state.modelReducer?.base?.viewport;
}

export function getModelVersionId(state: RootReducer) {
  return state.modelReducer.base.modelVersionId;
}

export function getScriptsFileId(state: RootReducer) {
  return state.modelReducer.base.scriptsFileId;
}

export function getScriptsContent(state: RootReducer) {
  return state.modelReducer.base.scriptsContent;
}

export function getFilterAlpha(state: RootReducer) {
  return state.modelReducer.base.filterAlpha;
}

export function getContextMenu(state: RootReducer) {
  return state.modelReducer.base.contextMenu;
}

export function getModelName(state: RootReducer) {
  return state.modelReducer.base.name;
}