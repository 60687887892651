import { PrunedModelForHistory, deepFreeze } from './utils-extra';

export type ModelHistory = InstanceType<typeof ModelHistorySingleton>;

class ModelHistorySingleton {
  private _present?: PrunedModelForHistory;
  past: any[];
  future: any[];
  useFreeze = true;

  constructor({ useFreeze = true } = {}) {
    this.past = [];
    this.future = [];
    this.useFreeze = useFreeze;
  }

  get present(): PrunedModelForHistory | undefined {
    return this._present;
  }

  set present(val) {
    this._present = this.useFreeze ? deepFreeze(val) : val;
  }

  get pastSize() {
    return this.past.length;
  }

  get futureSize() {
    return this.future.length;
  }

  undo() {
    const previous = this.past.pop();

    if (previous) {
      this.future.push(this.present);
      this.present = previous;
    }
  }

  redo() {
    const next = this.future.pop();

    if (next) {
      this.past.push(this.present);
      this.present = next;
    }
  }

  clear() {
    this.past = [];
    this.future = [];
    this.present = undefined;
  }

  clearPast() {
    this.past = [];
  }

  clearFuture() {
    this.future = [];
  }
}

export const modelHistory = new ModelHistorySingleton();
