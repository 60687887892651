import { createAction } from '@reduxjs/toolkit';
import type { PrunedModel } from 'types';
import { MODEL } from 'utils/constants';

export const hydrateModel = createAction<PrunedModel>('model/HYDRATE');

export const replaceShapeEdges = createAction<{
  nodeId: string;
  edgeIds: string[];
}>(MODEL.REPLACE_SHAPE_EDGES);
