/* eslint-disable no-console */
import isEqual from 'lodash/isEqual';
import { EventManager } from 'metra-events';
import { clear, clearRedo, MODEL_HISTORY } from 'modules/model/history/actions';
import type { Middleware } from 'redux';
import { RootReducer } from 'types';
import { ENGINE, GLOBAL_EVENT, MODEL } from 'utils/constants';
import { modelHistory } from 'utils/model-history';
import { pruneModelForHistory } from 'utils/utils-extra';
import { diffObj } from 'utils/utils';
import { t3dev } from 't3dev';
import { getUndoableFn } from 'modules/model/history/undoable-action';
import { UNIVERSAL_UNDO, UNIVERSAL_REDO } from 'modules/model/base/actions';

const groupStyle = 'background: red; color: #fff; padding: 2px 4px;';
const infoStyle = 'background: #444; color: #fff; padding: 2px 4px;';

const logger = t3dev().logger('modelHistory');

export const history: Middleware<{}, RootReducer> =
  (store) => (next) => (action) => {
    switch (action.type) {
      case MODEL.PIXI_READY: {
        logger.groupCollapsed(`%c${action.type}`, groupStyle);
        // initialize history with the first snapshot of modelState
        const state = store.getState();
        const modelState = state.modelReducer;
        modelHistory.present = pruneModelForHistory(modelState);
        logger.info(
          '%cInitialize Universal Model History with first snapshot of model state',
          infoStyle,
          modelHistory
        );

        logger.groupEnd();
        return next(action);
      }
      case MODEL_HISTORY.CREATE: {
        logger.groupCollapsed(`%c${action.payload.undo[0].name}`, groupStyle);
        const state = store.getState();
        const modelState = state.modelReducer;
        const prunedModelState = pruneModelForHistory(modelState);
        const hasChanged = !isEqual(modelHistory.present, prunedModelState);
        const isUniversalUndo = action.payload.undo[0].name === UNIVERSAL_UNDO;

        logger.groupCollapsed('%cstack trace', infoStyle);
        logger.trace();
        logger.groupEnd();

        // add a universal history entry. the number of past entries (modelHistory.past) should
        // match modelReducer.history.undoActions. the idea here is to keep them both in sync
        // with each other
        if (hasChanged) {
          logger.info(
            '%cRedux Model State has changed',
            infoStyle,
            diffObj(modelHistory.present, prunedModelState)
          );

          // Clear Redo Stack on new action
          if (modelState.history.redoActions.length) {
            modelHistory.clearFuture();
            store.dispatch(clearRedo());
          }

          if (isUniversalUndo) {
            modelHistory.past.push(modelHistory.present);

            logger.info(
              '%cIts a Universal History entry. Update the Model History Past state',
              infoStyle,
              modelHistory
            );
          }

          modelHistory.present = prunedModelState;

          logger.info(
            '%cUpdate Model History present state',
            infoStyle,
            modelHistory
          );

          logger.groupEnd();
          return next(action);
        }

        logger.info(
          '%cNo change has been detected. Nothing has been done.',
          infoStyle
        );

        logger.groupEnd();
        return;
      }
      case MODEL_HISTORY.UNDO: {
        logger.groupCollapsed(`%c${action.type}`, groupStyle);
        const state = store.getState();
        const modelState = state.modelReducer;
        const undoHistory = modelState.history.undoActions;
        const undoHistoryEntry = undoHistory[undoHistory.length - 1];

        if (undoHistoryEntry) {
          const undoActionSignatures = undoHistoryEntry.undo;

          logger.info(
            '%cA Redux Undo History entry exist.',
            infoStyle,
            undoActionSignatures
          );

          // its possible that one undo action may need to call multiple actions to completely undo
          // the user's performed action
          undoActionSignatures.forEach((actionSignature) => {
            const isUniversalSignature =
              actionSignature.name === UNIVERSAL_UNDO;

            // handle undoable wrapper functions
            const undoableFn = getUndoableFn(actionSignature.name);
            if (undoableFn) {
              const args = actionSignature.args;
              store.dispatch(undoableFn(...args));
            } else if (!isUniversalSignature) {
              throw new Error(
                `undoable action '${actionSignature.name}' not found in registry on undo. This should never happen`
              );
            }

            // handle universal modelHistory
            if (
              isUniversalSignature &&
              modelHistory.pastSize &&
              modelHistory.present
            ) {
              modelHistory.undo();
              store.dispatch({
                type: MODEL_HISTORY.UNIVERSAL,
                payload: modelHistory.present,
              });
              EventManager.emit(GLOBAL_EVENT.HISTORY_UNDO);
              EventManager.emit(
                ENGINE.REBUILD,
                modelHistory.present,
                modelState
              );
              logger.info(
                `%cA Model History undo method has been called. The ${MODEL_HISTORY.UNIVERSAL} Action has been dispatched with new model state. Engine has been rebuilt.`,
                infoStyle,
                modelHistory
              );
            }
          });
        }

        const prunedModelState = pruneModelForHistory(
          store.getState().modelReducer
        );
        modelHistory.present = prunedModelState;

        logger.info(
          '%cUpdate Model History present state',
          infoStyle,
          modelHistory
        );

        logger.groupEnd();

        // forward the current action
        return next(action);
      }
      case MODEL_HISTORY.REDO: {
        logger.groupCollapsed(`%c${action.type}`, groupStyle);

        const state = store.getState();
        const modelState = state.modelReducer;
        const redoHistory = modelState.history.redoActions;
        const redoHistoryEntry = redoHistory[redoHistory.length - 1];

        if (redoHistoryEntry) {
          const redoActionSignatures = redoHistoryEntry.redo;

          logger.info(
            '%cA Redux Redo History entry exist.',
            infoStyle,
            redoActionSignatures
          );

          // its possible that one redo action may need to call multiple actions to completely redo
          // the user's performed action
          redoActionSignatures.forEach((actionSignature) => {
            const isUniversalSignature =
              actionSignature.name === UNIVERSAL_REDO;

            // handle undoable wrapper functions
            const undoableFn = getUndoableFn(actionSignature.name);
            if (undoableFn) {
              const args = actionSignature.args;
              store.dispatch(undoableFn(...args));
            } else if (!isUniversalSignature) {
              throw new Error(
                `undoable action '${actionSignature.name}' not found in registry on redo. This should never happen`
              );
            }

            // handle universal modelHistory
            if (
              isUniversalSignature &&
              modelHistory.futureSize &&
              modelHistory.present
            ) {
              modelHistory.redo();
              store.dispatch({
                type: MODEL_HISTORY.UNIVERSAL,
                payload: modelHistory.present,
              });
              EventManager.emit(GLOBAL_EVENT.HISTORY_REDO);
              EventManager.emit(
                ENGINE.REBUILD,
                modelHistory.present,
                modelState
              );

              logger.info(
                `%cA Model History redo method has been called. The ${MODEL_HISTORY.UNIVERSAL} Action has been dispatched with new model state. Engine has been rebuilt.`,
                infoStyle,
                modelHistory
              );
            }
          });
        }

        const prunedModelState = pruneModelForHistory(
          store.getState().modelReducer
        );
        modelHistory.present = prunedModelState;

        logger.info(
          '%cUpdate Model History present state',
          infoStyle,
          modelHistory
        );

        logger.groupEnd();

        // forward the current action
        return next(action);
      }
      case MODEL.RESET: {
        logger.groupCollapsed(`%c${action.type}`, groupStyle);

        store.dispatch(clear());

        logger.info('%cClear action has been dispatched', infoStyle);

        logger.groupEnd();

        return next(action);
      }
      case MODEL_HISTORY.CLEAR: {
        logger.groupCollapsed(`%c${action.type}`, groupStyle);

        // clears both modelHistorySingleton and redux store history.
        modelHistory.clear();

        logger.info(
          '%Model History clear method has been called',
          infoStyle,
          modelHistory
        );

        logger.groupEnd();

        return next(action);
      }
      default: {
        return next(action);
      }
    }
  };
