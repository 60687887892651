import {
  Bounds,
  Order,
  ShapeUUID,
  MetraLayer,
  Position,
  Scale,
  Alpha,
  Selectable,
  Moveable,
  Text,
  Visible,
  Color,
  DarkMode,
  Displayables,
  DrawingText,
  Interactive,
} from 'engine/components';
import { SubAtoms } from '../sub-atoms';
import type { Query } from '..';
import { v4 as uuid } from 'uuid';
import { Vector2 } from 'utils/vector';
import { ECS_GROUP, HEX_STRING, LAYER, SHAPE } from 'utils/constants';
import { TextConfig, UUID } from 'types';
import { addShapeByConfig, updateShapeByConfig } from 'modules/model/pixi';
import {
  getTextResolution,
  isSome,
  getSnapPosition,
  getZoom,
} from 'helpers/utils';
import { Rectangle, Text as PText, TextStyle } from 'pixi.js';
import { RectangleRange } from 'engine/range';
import { colorParse } from 'utils/utils';
import { remember } from 'modules/model/base/actions';

export class TextAtoms extends SubAtoms {
  private _textShapes!: Query;

  init() {
    this._textShapes = this.sys2.query(Text);
  }

  createText(value: string, coords: Vector2) {
    const textColor = this.sys2.state.modelReducer.base.textColor;
    const textAlpha = this.sys2.state.modelReducer.base.textAlpha;
    const fontSize = Number(this.sys2.state.modelReducer.base.textFontSize);
    const textStrokeAlpha = this.sys2.state.modelReducer.base.textStrokeAlpha;
    const textStrokeColor = this.sys2.state.modelReducer.base.textStrokeColor;
    const textStrokeThickness = Number(
      this.sys2.state.modelReducer.base.textStrokeThickness
    );

    // set the style
    const style = new TextStyle({
      lineJoin: 'round',
      fontSize: fontSize,
      fill: textColor,
      stroke: textStrokeColor,
      strokeThickness: textStrokeThickness,
    });

    const { x, y } = this.sys2.engine.camera.fixedToWorld(coords);

    // create pixi text object
    const textObject = new PText(value, style);
    textObject.x = x;
    textObject.y = y;
    textObject.alpha = textAlpha;
    textObject.resolution = getTextResolution(textObject, this.sys2.engine.app);
    textObject.zIndex = this._textShapes.all.size;

    // If snap to grid is active, set the snap position
    if (this.sys2.state.appReducer.grid.snap) {
      const zoom = getZoom(this.sys2.engine);
      const snappedPosition = getSnapPosition(
        zoom,
        this.sys2.state.modelReducer.gridSettings,
        new Vector2(x, y)
      );
      textObject.x = snappedPosition.x;
      textObject.y = snappedPosition.y;
    }

    const displayables = new Displayables();
    displayables.val = [
      {
        asset: textObject,
        textureName: null,
        layer: LAYER.SHAPE,
      },
    ];

    const drawingText = new DrawingText();
    drawingText.asset = textObject;

    const text = new Text();
    text.fill.val = textColor;
    text.fontSize.val = fontSize;
    text.textString.val = value;
    text.stroke.val = textStrokeColor;
    text.strokeThickness.val = textStrokeThickness;
    text.strokeAlpha.val = textStrokeAlpha;

    const position = new Position();
    // set position to the center of the text
    const centerX = textObject.x + textObject.width / 2;
    const centerY = textObject.y + textObject.height / 2;
    position.value = new Vector2(centerX, centerY);

    const shapeUUID = new ShapeUUID();
    shapeUUID.value = uuid();

    const layer = new MetraLayer();
    layer.value = LAYER.SHAPE;

    const color = new Color();
    color.value = colorParse(textColor);

    const order = new Order();
    order.value = this._textShapes.all.size;

    const scale = new Scale();
    scale.value = Vector2.fromPoint({ x: 1, y: 1 });

    const bounds = new Bounds();
    bounds.value = new Rectangle(0, 0, textObject.width, textObject.height);

    const alpha = new Alpha();
    alpha.value = textAlpha;

    const eid = this.sys2.createEntity([
      bounds,
      text,
      position,
      shapeUUID,
      layer,
      order,
      scale,
      displayables,
      drawingText,
      alpha,
      new Interactive(),
      color,
      new Selectable(),
      new Moveable(),
      new Visible(),
    ]);

    const entity = this.sys2.engine.ecs.getEntity(eid);
    if (isSome(entity)) {
      this.sys2.engine.ecs.tagManager.tagEntity(shapeUUID.value, entity);
      this.sys2.engine.ecs.groupManager.addEntityToGroup(
        ECS_GROUP.TEXT,
        entity
      );
    }

    // if we are using default black or white colors, add DarkMode
    // component to update the color when switching between dark mode
    const defaultColor =
      text.fill.val === HEX_STRING.WHITE || text.fill.val === HEX_STRING.BLACK;
    if (defaultColor && textStrokeThickness === 0)
      this.sys2.ensure(eid, new DarkMode());

    const textShapeConfig: TextConfig = {
      alpha: textAlpha,
      color: textColor,
      id: shapeUUID.value,
      layer: LAYER.SHAPE,
      order: order.value,
      pos: {
        x: position.val.x,
        y: position.val.y,
      },
      scale: scale.value,
      type: SHAPE.TEXT,
      visible: true,
      hidden: false,
      width: Math.round(textObject.width),
      height: Math.round(textObject.height),
      fontSize: fontSize,
      text: value,
      stroke: textStrokeColor,
      strokeThickness: textStrokeThickness,
      strokeAlpha: textStrokeAlpha,
    };

    this.atoms.dispatch(addShapeByConfig(textShapeConfig));
    this.atoms.dispatch(remember());
    this.sys2.engine.ecs.resolveById(eid);
  }

  editText(textString: string, uuid: UUID) {
    const shapeConfig = this.sys2.state.modelReducer.shapes[uuid];
    const updatedConfig = {
      ...shapeConfig,
      text: textString,
    };

    const entity = this.sys2.engine.ecs.getEntityByTag(uuid);
    if (!entity) return;
    const text = this.sys2.assert(entity.id, Text);
    text.textString.val = textString;

    this.atoms.dispatch(updateShapeByConfig(updatedConfig));
    this.atoms.dispatch(remember());
  }
}
