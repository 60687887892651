import React from 'react';
import { MetraMedia } from 'types/modules/entities';
import BrokenLinkIcon from './BrokenLinkIcon';
import {
  isMetraFileDownloadURL,
  isMetraFolderURL,
  isMetraModelURL,
} from 'utils/url-builders';
import { findValidUrlsOrNoteLinks, mergeTextsAndUrls } from 'utils/utils';
import { ImmutableObject } from 'seamless-immutable';
import { extractSearchText } from 'modules/model/modelLinks/helpers';

type PartialMedia =
  | Partial<Record<string, MetraMedia>>
  | Partial<ImmutableObject<Record<string, MetraMedia>>>;

type PartialVersion =
  | Partial<Record<number, { media: number }>>
  | Partial<ImmutableObject<Record<number, { media: number }>>>;

type NamedThings = Partial<Record<string, { name: string | undefined }>>;

export const getMediaName = (
  precedingElementName: string,
  url: string,
  allNames: NamedThings
) => {
  const { pathname, search } = new URL(url);
  const urlElements = pathname.split('/');
  const index = urlElements.indexOf(precedingElementName);
  const itemId = urlElements[index + 1];

  if (
    !!itemId &&
    precedingElementName === 'files' &&
    index === urlElements.length - 1
  ) {
    return 'files';
  }

  let mediaName = allNames?.[itemId]?.name;
  if (mediaName && search) {
    const [searchText, _isAdvanced] = extractSearchText(search);
    mediaName = mediaName + ` (search: ${searchText})`;
  }

  return mediaName ?? url;
};

export const getDownloadFileName = (
  url: string,
  allMedia: PartialMedia,
  allVersions: PartialVersion
) => {
  if (url.includes('/versions/')) {
    const versionNames: NamedThings = {};
    Object.values(allVersions).forEach((version) => {
      versionNames[version.id] = { name: allMedia[version.media]?.name };
    });
    return getMediaName('versions', url, versionNames);
  } else {
    return getMediaName('media', url, allMedia);
  }
};

export const getFolderName = (url: string, allMedia: PartialMedia) => {
  const { pathname, search } = new URL(url);
  const urlElements = pathname.split('/');
  const mediaId = urlElements[urlElements.length - 1];
  if (mediaId === 'files') {
    return 'Files';
  }
  return allMedia[mediaId]?.name;
};

export const getModelFileName = (url: string, allMedia: PartialMedia) => {
  return getMediaName('models', url, allMedia);
};

export const isMetraDownloadUrl = (url: string) => {
  return isMetraFileDownloadURL(url);
};

export function getDisplayText(
  url: string,
  allMedia: PartialMedia,
  allVersions: PartialVersion
) {
  return isMetraFileDownloadURL(url)
    ? getDownloadFileName(url, allMedia, allVersions)
    : isMetraModelURL(url)
    ? getModelFileName(url, allMedia)
    : isMetraFolderURL(url)
    ? getFolderName(url, allMedia)
    : null;
}

export const displayUrl = (
  url: string,
  allMedia: PartialMedia,
  allVersions: PartialVersion
) => {
  const displayText = getDisplayText(url, allMedia, allVersions);

  // If a Metra file and unable to find the file name,
  // original URL is returned indicating the file was not found
  if (displayText === url) {
    return <BrokenLinkIcon link={url} />;
  }

  if (displayText) {
    return (
      <a href={url} onClick={(e) => e.preventDefault()}>
        {displayText}
      </a>
    );
  } else {
    return url;
  }
};

export function getTextParts(urls: string[], value: string) {
  // we used to do value.split(regex-that-idenfies-urls) but that had problems
  // with the localhost url's.  Replacing it with this workable but less slick
  // and easily-reused logic.
  const texts = [];
  let searchIndex = 0;
  for (let i = 0; i < urls.length; i++) {
    const nextIndex = value.indexOf(urls[i], searchIndex);
    texts.push(value.substring(searchIndex, nextIndex));
    searchIndex = nextIndex + urls[i].length;
  }
  texts.push(value.substring(searchIndex));

  return texts;
}

export function getTextUrlMixDisplayValue(
  value: string,
  allMedia: PartialMedia,
  allVersions: PartialVersion
) {
  const urls = findValidUrlsOrNoteLinks(value);
  const texts = getTextParts(urls, value);
  const mappedUrls = urls.map((url) => {
    return getDisplayText(url, allMedia, allVersions) || url;
  });
  const merged = mergeTextsAndUrls(texts, mappedUrls);

  return merged.join('');
}

/**
 * When text is interspersed with urls, find and styles the urls.
 * Then, return a list of components that mixes the plain text with the styled urls.
 * @param value
 * @returns Urls styled and mixed within plain text
 */
export function textUrlMix(
  value: string,
  allMedia: PartialMedia,
  allVersions: PartialVersion
): Array<React.ReactElement> {
  const urls = findValidUrlsOrNoteLinks(value);
  const texts = getTextParts(urls, value);
  const merged = mergeTextsAndUrls(texts, urls);

  return merged.map((item, i) =>
    i % 2 === 0 ? (
      <span key={`${i}-${item}`}>{item}</span>
    ) : (
      <span key={`${i}-${item}`} className="mod-url">
        {displayUrl(item, allMedia, allVersions)}
      </span>
    )
  );
}
