import { GID, gid, newestGid } from '../gid';
import {
  addCategoryMembers,
  createCategory,
  createProperty,
  deleteCategory,
  deleteProperty,
  renameCategory,
  renameProperty,
} from '../schema/actions';
import type { ThunkAction, ThunkActionFunc } from 'types';
import { createAction } from '@reduxjs/toolkit';
import { remember } from '../base/actions';
import {
  getSheetColumns,
  getSheetRows,
  getSheetSelectionsBySheetId,
} from './selectors';
import { onRedo, onUndo, undoableAction } from '../history/undoable-action';
import { SheetSelections } from './types';

// add a new selection without clearing previous (cmd-click)
export const addSheetSelection = createAction<{
  sheet: GID;
  row: string;
  column: string;
}>('model/sheet/selection/ADD');

// replace existing selection with the new selection (click)
export const replaceSheetSelection = createAction<{
  sheet: GID;
  row: string;
  column: string;
}>('model/sheet/selection/REPLACE');

// clear all sheets selections entirely (e.g. when selecting something else on the on canvas)
export const clearAllSheetsSelection = createAction(
  'model/sheet/selection/CLEAR_ALL_SHEETS_SELECTIONS'
);

// clear selection entirely (ESC)
export const clearSheetSelection = createAction<{ sheet: GID }>(
  'model/sheet/selection/CLEAR'
);

// remove a selected cell without clearing others (cmd-click)
export const removeSheetSelection = createAction<{
  sheet: GID;
  row: string;
  column: string;
}>('model/sheet/selection/REMOVE');

// Remove the currently selected column selection
export const removeSheetColumnSelection = createAction<{
  sheet: GID;
  row: string;
  column: string;
}>('model/sheet/selection/REMOVE_SHEET_COLUMN_SELECTION');

// Remove the currently selected row selection
export const removeSheetRowSelection = createAction<{
  sheet: GID;
  row: string;
  column: string;
}>('model/sheet/selection/REMOVE_SHEET_ROW_SELECTION');

// extend the most recent selection to the specified cell (shift-click)
export const extendSheetSelection = createAction<{
  sheet: GID;
  row: string;
  column: string;
}>('model/sheet/selection/EXTEND');

export const extrudeSheetSelection = createAction<{
  sheet: GID;
  row: string;
  column: string;
}>('model/sheet/selection/EXTRUDE');

// replace existing selection with a different selection state (undo/redo)
export const _replaceSheetSelectionState = createAction<{
  sheet: GID;
  sheetSelections: SheetSelections;
}>('model/sheet/selection/REPLACE_STATE');

export const replaceSheetSelectionState = undoableAction(
  _replaceSheetSelectionState
);

export const createCategorySheet: ThunkActionFunc<[string], GID> =
  (name) => (dispatch, _getState) => {
    dispatch(createSheet({ name, type: 'category' }));
    const sheet = newestGid();
    dispatch(createCategory({ name, sheet }));
    const categoryId = newestGid();
    dispatch(addRows({ sheet, rows: [categoryId] }));
    dispatch(remember());
    return sheet;
  };

export const setCategorySheetValue: ThunkActionFunc<
  [string, string, string, string],
  string
> = (sheet, row, column, value) => (dispatch, getState) => {
  const { schema } = getState().modelReducer;
  dispatch(setValue({ sheet, row, column, value }));

  // if this is the category row (IE first row)
  // then we want to set the property name for this column as well
  if (schema.categories.bySheet[sheet] === row) {
    dispatch(renameProperty({ property: column, name: value }));
  }

  const valueId =
    getState().modelReducer.sheets.byId[sheet].entries[row][column];
  return valueId;
};

const _addCategorySheetProperty: ThunkActionFunc<
  [string, string, Option<string>]
> = (sheet, name, id) => (dispatch, getState) => {
  const { schema } = getState().modelReducer;
  const category = schema.categories.bySheet[sheet];

  // create property on the schema side
  const propid = id || gid();
  dispatch(createProperty({ name, category, id: propid }));

  // add the property as a column
  dispatch(addColumns({ sheet, columns: [propid] }));

  // set the property name as a row value. Name row is keyed by category ID
  dispatch(setCategorySheetValue(sheet, category, propid, name));

  onUndo(deleteSheetColumns({ sheet, columns: [propid] }));
  onRedo(addCategorySheetProperty(sheet, name, propid));
};
export const addCategorySheetProperty = undoableAction(
  _addCategorySheetProperty
);

export const _deleteSheetColumns: ThunkActionFunc<
  [{ sheet: string; columns: string[] }]
> =
  ({ sheet, columns }) =>
  (dispatch, getState) => {
    const category = getState().modelReducer.schema.categories.bySheet[sheet];

    if (category) {
      for (const property of columns) {
        dispatch(deleteProperty({ property }));
      }
    }

    dispatch(removeColumns({ sheet, columns }));
  };
export const deleteSheetColumns = undoableAction(_deleteSheetColumns);

export const addCategorySheetRows: ThunkActionFunc<[GID, string[]]> =
  (sheet, entryIds) => (dispatch, getState) => {
    const { schema } = getState().modelReducer;
    const category = schema.categories.bySheet[sheet];
    dispatch(addCategoryMembers({ category, members: entryIds }));
    dispatch(addRows({ sheet, rows: entryIds }));
    dispatch(remember());
  };

export const renameSheet: ThunkActionFunc<[string, string]> =
  (sheet, name) => (dispatch, getState) => {
    const {
      sheets,
      schema: { categories },
    } = getState().modelReducer;
    if (sheets.byId[sheet].type === 'category') {
      dispatch(renameCategory({ category: categories.bySheet[sheet], name }));
    }
    dispatch(_renameSheet({ sheet, name }));
    dispatch(remember());
  };

export const deleteSheet: ThunkActionFunc<[string]> =
  (sheet) => (dispatch, getState) => {
    const {
      sheets,
      schema: { categories },
    } = getState().modelReducer;
    if (sheets.byId[sheet].type === 'category') {
      dispatch(deleteCategory({ category: categories.bySheet[sheet] }));
    }
    dispatch(_deleteSheet({ sheet }));
    dispatch(remember());
  };

export const createSheet = createAction<{ name: string; type: string }>(
  'model/sheet/CREATE'
);
export const _deleteSheet = createAction<{ sheet: GID }>('model/sheet/DELETE');

export const _renameSheet = createAction<{ sheet: GID; name: string }>(
  'model/sheet/RENAME'
);

export const setEvaluated = createAction<{
  sheet: GID;
  valueId: GID;
  evaluated: string;
}>('model/sheet/value/EXPRESSION');
export const setError = createAction<{
  sheet: GID;
  valueId: GID;
  error: string;
}>('model/sheet/value/ERROR');

export const setValue = createAction<{
  sheet: GID;
  row: GID;
  column: GID;
  value: string;
}>('model/sheet/value/SET');

export const _addRows = createAction<{ sheet: GID; rows: string[] }>(
  'model/sheet/row/ADD_MANY'
);
export const addRows = undoableAction(_addRows);

export const _addColumns = createAction<{ sheet: GID; columns: string[] }>(
  'model/sheet/column/ADD_MANY'
);

export const addColumns = undoableAction(_addColumns);

export const deleteColumns = createAction<{ sheet: GID; columns: string[] }>(
  'model/sheet/column/DELETE_MANY'
);

export const deleteRows = createAction<{ sheet: GID; rows: string[] }>(
  'model/sheet/row/DELETE_MANY'
);

export const setActiveSheet = createAction<{ sheet: GID }>(
  'model/sheet/SET_ACTIVE_SHEET'
);

// used to entirely replace the sheet columns. helpful when you need to retain the order
// columns when replacing them
export const _replaceSheetColumns = createAction<{
  sheet: GID;
  columns: string[];
}>('model/sheet/columns/REPLACE_COLUMNS');
export const replaceSheetColumns = undoableAction(_replaceSheetColumns);

type RemoveActiveSheetColumnSelectionsArgs = { sheetId?: Option<GID> };
export const removeActiveSheetColumnSelections = undoableAction(
  function _removeActiveSheetColumnSelections({
    sheetId,
  }: RemoveActiveSheetColumnSelectionsArgs = {}): ThunkAction<string[]> {
    return (dispatch, getState) => {
      const state = getState();
      let sheet = sheetId;
      sheet ||= state.modelReducer.sheets.active;

      if (sheet) {
        const sheetSelections = getSheetSelectionsBySheetId(sheet)(state);
        const currentSheetColumns = getSheetColumns(sheet)(state);

        if (sheetSelections) {
          const columns = sheetSelections.ranges.flatMap((range) => {
            return range.map((selection) => {
              const [row, column] = selection;

              return column;
            });
          });

          dispatch(
            deleteColumns({
              sheet,
              columns,
            })
          );

          onUndo(replaceSheetColumns({ columns: currentSheetColumns, sheet }));
          onRedo(removeActiveSheetColumnSelections({ sheetId }));

          return columns;
        }
        return [];
      }
      return [];
    };
  }
);

// used to entirely replace the sheet columns. helpful when you need to retain the order
// columns when replacing them
export const _replaceSheetRows = createAction<{
  sheet: GID;
  rows: string[];
}>('model/sheet/rows/REPLACE_ROWS');
export const replaceSheetRows = undoableAction(_replaceSheetRows);

// remove rows from sheet only
export const removeActiveSheetRowSelections = undoableAction(
  function _removeActiveSheetRowSelections({
    sheetId,
  }: { sheetId?: Option<GID> } = {}): ThunkAction<string[]> {
    return (dispatch, getState) => {
      const state = getState();
      let sheet = sheetId;
      sheet ||= state.modelReducer.sheets.active;

      if (sheet) {
        const sheetSelections = getSheetSelectionsBySheetId(sheet)(state);
        const currentSheetRows = getSheetRows(sheet)(state);

        if (sheetSelections) {
          const rows = sheetSelections.ranges
            .flatMap((range) => {
              return range.map((selection) => {
                const [row] = selection;

                return row;
              });
            })
            .filter((row) => !row.startsWith('g'));

          if (rows.length) {
            dispatch(
              deleteRows({
                sheet,
                rows,
              })
            );

            onUndo(replaceSheetRows({ rows: currentSheetRows, sheet }));
            onRedo(removeActiveSheetRowSelections({ sheetId }));

            return rows;
          }

          return [];
        }

        return [];
      }

      return [];
    };
  }
);

export const removeColumns = createAction<{ sheet: GID; columns: string[] }>(
  'model/sheet/column/REMOVE_MANY'
);

// Sheet configuration

export const createSheetConfig = createAction<{
  sheetID: GID;
}>('model/sheet/config/CREATE_SHEET_CONFIG');

export const setColumnRowSize = createAction<{
  sheetID: GID;
  rowIndex: number;
  columnIndex: number;
  size: number[];
}>('model/sheet/config/SET_COlUMN_ROW_SIZE');

export const resizeCell: ThunkActionFunc<
  [
    {
      sheetID: GID;
      rowIndex: number;
      columnIndex: number;
      size: number[];
    }
  ]
> =
  ({ sheetID, rowIndex, columnIndex, size }) =>
  (dispatch, getState) => {
    const state = getState();

    // create a sheet config if one does not exist
    if (!state.modelReducer.sheets.config.sheetIds.includes(sheetID)) {
      dispatch(createSheetConfig({ sheetID }));
    }

    // UI state for other components to know if their cell is being resized
    // also used for disabling cell selection while resizing
    dispatch(
      setSheetResizingRowColumnIDs({
        sheetID,
        columnIndex,
        rowIndex,
      })
    );

    dispatch(setColumnRowSize({ columnIndex, rowIndex, sheetID, size }));
  };

export const setSheetResizingRowColumnIDs = createAction<{
  sheetID: GID;
  columnIndex: Option<number>;
  rowIndex: Option<number>;
}>('model/sheet/config/SET_UI_STATE');
