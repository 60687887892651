import { genericActionsForType } from 'modules/common/generic';
import { EventMode } from 'pixi.js';
import type {
  BaseEntityType,
  MetraConstant,
  MetraConstantValues,
  ModelSchemaCategories,
  MptTabTypes,
  MptTabValues,
  NodePrefixType,
} from 'types';

export const EntityType: Record<Uppercase<BaseEntityType>, BaseEntityType> = {
  NODES: 'Nodes',
  EDGES: 'Edges',
  SETS: 'Sets',
} as const;

const ShapeTypes = [
  'background',
  'circle',
  'collapsed_set',
  'composite',
  'drawing',
  'edge',
  'ellipse',
  'graph',
  'line',
  'multi_select',
  'node',
  'pending_edge',
  'rectangle',
  'selector',
  'set',
  'shape',
  'text',
] as const;

export type BaseShapeTypes = IntoUnion<typeof ShapeTypes>;

export const SHAPE: MetraConstant<'shape', BaseShapeTypes> = {
  BACKGROUND: 'shape/BACKGROUND',
  CIRCLE: 'shape/CIRCLE',
  COLLAPSED_SET: 'shape/COLLAPSED_SET',
  COMPOSITE: 'shape/COMPOSITE',
  DRAWING: 'shape/DRAWING',
  EDGE: 'shape/EDGE',
  ELLIPSE: 'shape/ELLIPSE',
  GRAPH: 'shape/GRAPH',
  LINE: 'shape/LINE',
  MULTI_SELECT: 'shape/MULTI_SELECT',
  NODE: 'shape/NODE',
  PENDING_EDGE: 'shape/PENDING_EDGE',
  RECTANGLE: 'shape/RECTANGLE',
  SELECTOR: 'shape/SELECTOR',
  SET: 'shape/SET',
  SHAPE: 'shape/SHAPE',
  TEXT: 'shape/TEXT',
} as const;

export const ShapeTransitionMemory = 'shape-transitions' as const;

export const NON_ENTITY_MPT_TAB = {
  MODEL_PROP: 'nonEntityMtpTab/MODEL_PROP',
  MODEL_CALC: 'nonEntityMtpTab/MODEL_CALC',
  MODEL_NOTES: 'nonEntityMptTab/MODEL_NOTES',
  PATHS: 'nonEntityMptTab/PATH',
} as const;

/*
 * TABS
 */

const TabTypes = [
  'guilds',
  'library',
  'projects',
  'projects',
  'nodes',
  'edges',
  'sets',
  'model_props',
  'model_calcs',
  'model_notes',
  'paths',
  // 'project_files',
  // 'model_files',
  'scripts',
  'model_links',
  'categories',
] as const;

export const GridTabTypes = [
  'modelCalcs',
  'modelProps',
  'nodes',
  'edges',
  'sets',
  'paths',
];

export type ScreamingTabTypes =
  | 'EDGES'
  | 'MODEL_CALCS'
  | 'MODEL_PROPS'
  | 'NODES'
  | 'PATHS'
  | 'SETS';

export type BaseTabTypes = IntoUnion<typeof TabTypes>;
export type TabValues = MetraConstantValues<'tab', BaseTabTypes>;

export const TAB: MetraConstant<'tab', BaseTabTypes> = {
  GUILDS: 'tab/GUILDS',
  LIBRARY: 'tab/LIBRARY',
  PROJECTS: 'tab/PROJECTS',
  NODES: 'tab/NODES',
  EDGES: 'tab/EDGES',
  SETS: 'tab/SETS',
  MODEL_PROPS: 'tab/MODEL_PROPS',
  MODEL_CALCS: 'tab/MODEL_CALCS',
  MODEL_NOTES: 'tab/MODEL_NOTES',
  PATHS: 'tab/PATHS',
  // PROJECT_FILES: 'tab/PROJECT_FILES', unused
  // MODEL_FILES: 'tab/MODEL_FILES', unused
  SCRIPTS: 'tab/SCRIPTS',
  MODEL_LINKS: 'tab/MODEL_LINKS',
  CATEGORIES: 'tab/CATEGORIES',
} as const;

export const MPT_TABS = [
  TAB.NODES,
  TAB.EDGES,
  TAB.SETS,
  TAB.MODEL_PROPS,
  TAB.MODEL_CALCS,
  TAB.MODEL_NOTES,
  TAB.PATHS,
  TAB.CATEGORIES,
];

export const MPT_IMPORT_TABS = [
  TAB.NODES,
  TAB.SETS,
  TAB.MODEL_PROPS,
  TAB.MODEL_CALCS,
];

export const MPT_TAB_MAP: Record<MptTabValues, MptTabTypes> = {
  [TAB.NODES]: 'nodes',
  [TAB.EDGES]: 'edges',
  [TAB.SETS]: 'sets',
  [TAB.PATHS]: 'paths',
  [TAB.MODEL_PROPS]: 'modelProps',
  [TAB.MODEL_CALCS]: 'modelCalcs',
  [TAB.MODEL_NOTES]: 'modelNotes',
  [TAB.CATEGORIES]: 'categories',
} as const;

export const MPT_TYPE_MAP = {
  sets: SHAPE.SET,
  nodes: SHAPE.NODE,
  edges: SHAPE.EDGE,
  modelProps: NON_ENTITY_MPT_TAB.MODEL_PROP,
  modelCalcs: NON_ENTITY_MPT_TAB.MODEL_CALC,
  modelNotes: NON_ENTITY_MPT_TAB.MODEL_NOTES,
  paths: NON_ENTITY_MPT_TAB.PATHS,
} as const;

export const EntityCollection = {
  NODES: 'nodes',
  EDGES: 'edges',
  SETS: 'sets',
  MODEL_PROPS: 'modelProps',
  MODEL_CALCS: 'modelCalcs',
  PATHS: 'paths',
} as const;

/**
 * event key state types
 */
export const KEY_STATE = {
  UP: 'keyup',
  DOWN: 'keydown',
  PRESS: 'keypress',
  REPEAT: 'repeat',
} as const;

/**
 * The schema categories. Determines which tab to display the schema
 * on in the Properties Table editor.
 */
export const SCHEMA_CATEGORY: Record<
  Uppercase<ModelSchemaCategories>,
  ModelSchemaCategories
> = {
  NODES: 'nodes',
  EDGES: 'edges',
  SETS: 'sets',
  MODELPROPS: 'modelProps',
  MODELCALCS: 'modelCalcs',
  PATHS: 'paths',
} as const;

export const SCHEMA_CATEGORY_TYPES: ModelSchemaCategories[] = [
  'nodes',
  'edges',
  'sets',
  'modelCalcs',
  'modelProps',
  'paths',
];

export const NODE_PREFIX_TYPES: NodePrefixType[] = [
  'c_',
  'm_',
  'n_',
  'e_',
  's_',
  'p_',
];

export const NODE_TYPE_PREFIX: Record<ModelSchemaCategories, NodePrefixType> = {
  // ***************************************************************************
  // If you add a new node prefix you also need to add it to the cellRx, colRx,
  // and rowRx regular expressions in /interpreter/workers/helpers.ts; and to
  // other expressions in interpreter.ts, helpers.ts
  modelCalcs: 'c_',
  modelProps: 'm_',
  nodes: 'n_',
  edges: 'e_',
  sets: 's_',
  paths: 'p_',
  // ***************************************************************************
} as const;

export const PREFIX_TO_GLOBAL_TYPE: Record<
  NodePrefixType,
  ModelSchemaCategories
> = {
  c_: 'modelCalcs',
  m_: 'modelProps',
  n_: 'nodes',
  e_: 'edges',
  s_: 'sets',
  p_: 'paths',
} as const;

/**
 * various events supported by PIXI and the browser
 */
export const EVENT = {
  CLICK: 'pointertap',
  KEYBOARD: 'keyboard',
  KEYDOWN: 'keydown',
  KEYPRESSED: 'keypressed',
  KEYUP: 'keyup',
  KEYDOWN_PLUS: 'keydown+',
  KEYPRESSED_PLUS: 'keypressed+',
  KEYUP_PLUS: 'keyup+',
  MOUSEDOWN: 'pointerdown',
  MOUSEENTER: 'pointerenter',
  MOUSELEAVE: 'pointerleave',
  MOUSEMOVE: 'pointermove',
  MOUSEOUT: 'pointerout',
  MOUSEUP: 'pointerup',
  MOUSEUPOUTSIDE: 'pointerupoutside',
  MOUSEOVER: 'pointerover',
  POINTERENTER: 'pointerenter',
  POINTERLEAVE: 'pointerleave',
  POINTEROUT: 'pointerout',
  POINTEROVER: 'pointerover',
  POINTERTAP: 'pointertap',
  TOUCHEND: 'touchend',
  WHEEL: 'wheel',
  CONTEXTMENU: 'contextmenu',
  RIGHTDOWN: 'rightdown',
  RIGHTUP: 'rightup',
  ADDED: 'added',
  CHILDADDED: 'childAdded',
  CHILDREMOVED: 'childRemoved',
  DESTROYED: 'destroyed',
  REMOVED: 'removed',
} as const;

/**
 * engine event constants
 */
export const ENGINE = {
  EXPORT_AS_PNG: 'model/engine/EXPORT_AS_PNG',
  BUILD: {
    NODES: 'model/engine/build/NODES',
    EDGES: 'model/engine/build/EDGES',
  },
  CAMERA: {
    GET: {
      SELF: 'camera/get/SELF',
      VIEWPORT: 'camera/get/VIEWPORT',
    },
    ZOOM: {
      IN: 'camera/zoom/IN',
      FIT: 'camera/zoom/FIT',
      FIT_NEW_NODES: 'camera/zoom/FIT_NEW_NODES',
      FIT_SELECTION: 'camera/zoom/FIT_SELECTION',
      OUT: 'camera/zoom/OUT',
      SET: 'camera/zoom/SET',
    },
  },
  CONTEXT_MENU: {
    HIDE: 'model/engine/context/HIDE',
    SHOW: 'model/engine/context/SHOW',
  },
  CREATE: {
    ENTITIES: 'model/engine/create/ENTITIES',
    PASTED_ENTITIES: 'model/engine/create/PASTED_ENTITIES',
  },
  DESELECT: {
    NODES: 'model/engine/deselect/NODES',
    EDGES: 'model/engine/deselect/EDGES',
    ENTITIES: 'model/engine/deselect/ENTITIES',
    IMAGES: 'model/engine/deslect/IMAGES',
  },
  EVALUATE: {
    ALL: 'model/engine/evaluate/ALL',
  },
  REBUILD: 'model/enigne/REBUILD',
  REVERT: {
    SELECTION: {
      NODES: 'model/engine/revert/selection/NODES',
      EDGES: 'model/engine/revert/selection/EDGES',
      SETS: {
        START: 'model/engine/revert/selection/SETS/START',
        END: 'model/engine/revert/selection/SETS/END',
      },
    },
    NODE_IMAGES: {
      BY_CONFIGS: 'model/engine/revert/nodeImages/BY_CONFIGS',
    },
  },
  DELETE: {
    ASSETS: 'model/engine/delete/ASSETS',
    ROWS: 'model/engine/delete/ROWS',
    SETS_AND_MEMBERS: 'model/engine/delete/SETS_AND_MEMBERS',
    SETS_ONLY: 'model/engine/delete/SETS_ONLY',
  },
  GET: {
    SELF: 'model/engine/get/SELF',
  },
  LOAD: {
    SHAPE: {
      EPHEMERAL: 'model/engine/create/shape/EPHEMERAL',
      REGULAR: 'model/engine/create/shape/REGULAR',
    },
    SHAPES: 'model/engine/load/SHAPES',
  },
  UPDATE: {
    ASSETS: {
      REFRESH: 'model/engine/update/assets/REFRESH',
      RELOAD: 'model/engine/update/assets/RELOAD',
    },
    CANVAS: {
      COLOR: 'model/engine/update/canvas/COLOR',
    },
    EDGES: {
      ADD_WAYPOINT: 'model/engine/update/edges/ADD_WAYPOINT',
      REASSIGN: 'model/engine/update/edges/REASSIGN',
      SEGMENTS: 'model/edngine/update/edges/SEGMENTS',
      SWAP: 'model/engine/update/edges/SWAP',
    },
    ENTITY_VISIBILITY: 'model/engine/update/ENTITY_VISIBILITY',
    EXPRESSIONS: 'model/engine/update/EXPRESSION',
    FILTER_OPACITY: 'model/engine/update/FILTER_OPACITY',
    LABEL_COLOR: 'model/engine/update/LABEL_COLOR',
    LABEL_FONT_SIZE: 'model/engine/update/LABEL_FONT_SIZE',
    MULTI_SELECT: 'model/engine/update/MULTI_SELECT',
    SHAPES: {
      PROPERTIES: 'model/engine/update/shape/PROPERTIES',
      ALPHA: {
        BY_IDS: 'model/engine/update/shape/alpha/BY_IDS',
        BY_CONFIGS: 'model/engine/update/shape/alpha/BY_CONFIGS',
        IN_PLACE: 'model/engine/update/shape/alpha/IN_PLACE',
      },
      APPLY_GRAPH_LAYOUT: 'model/engine/update/APPLY_GRAPH_LAYOUT',
      COLOR: {
        BY_CONFIGS: 'model/engine/update/shape/color/BY_CONFIGS',
      },
      SET_COLOR: {
        BY_IDS: 'model/engine/update/shape/setColor/BY_IDS',
      },
      ASSETS: {
        FINALIZE: 'model/engine/update/shape/assets/FINALIZE',
        RELOAD: 'model/engine/update/shapes/assets/RELOAD',
        SET: 'model/engine/update/shape/assets/SET',
        DEFAULT: 'model/engine/update/shape/assets/DEFAULT',
      },
      BY_IDS: 'model/engine/update/shape/BY_IDS',
      BY_CONFIGS: '/model/engine/update/shape/BY_CONFIGS',
      LAYER: {
        BACKWARD: 'model/engine/update/shape/layer/BACKWARD',
        FORWARD: 'model/engine/update/shape/layer/FORWARD',
        TO_BACK: 'model/engine/update/shape/layer/TO_BACK',
        TO_FRONT: 'model/engine/update/shape/layer/TO_FRONT',
        TO_FOREGROUND: 'model/engine/update/shape/layer/TO_FOREGROUND',
        TO_BACKGROUND: 'model/engine/update/shape/layer/TO_BACKGROUND',
      },
      SET_MEMBERSHIP: 'model/engine/update/shape/SET_MEMBERSHIP',
      SHAPE: {
        SELECTED: 'model/engine/update/shapes/shape/SELECTED',
        COMPONENT: 'model/engine/update/shapes/shape/COMPONENT',
        PERIPHERAL: 'model/engine/update/shapes/shape/PERIPHERAL',
        STYLE: 'model/engine/update/shapes/shape/STYLE',
      },
      WIDTH: {
        BY_CONFIGS: 'model/engine/update/shapes/width/BY_CONFIGS',
      },
      VISIBILITY: {
        ALL: 'model/engine/update/shape/visibility/ALL',
        BY_IDS: 'model/engine/update/shape/visibility/BY_IDS',
      },
      ISOLATE: 'model/engine/update/shape/ISOLATE',
      UNISOLATE: 'model/engine/update/shape/UNISOLATE',
      DARK_MODE: 'model/engine/update/shape/DARK_MDOE',
    },
    TOOL: 'model/engine/update/TOOL',
  },
  UNLOAD: {
    SHAPE: {
      EPHEMERAL: 'model/engine/unload/shape/EPHEMERAL',
      REGULAR: 'model/engine/unload/shape/REGULAR',
    },
  },
  TOGGLE: {
    STATSJS: 'model/engine/toggle/GLBENCH',
  },
  REQUEST: {
    INSTANCE: 'model/engine/request/INSTANCE',
  },
  SELECT: {
    ALL: 'model/engine/select/ALL',
  },
  TICK: 'model/engine/TICK',
} as const;

export declare type EngineEventTypes = ValueOf<typeof ENGINE>;

export const FIXED_PROP_UUIDS = {
  PATHS: {
    PATH_QUERY: {
      uuid: '1737a537-04d9-4164-b9b8-84a400c633ae',
      label: 'Path Query',
    },
    SOURCE: {
      uuid: 'e9bae7e8-9afa-4ac7-8154-678d52962293',
      label: 'Source',
    },
    TARGET: {
      uuid: '4a70902c-0165-4c3f-bfa3-24e3f24204c9',
      label: 'Target',
    },
    LENGTH: {
      uuid: 'a7789012-d361-4d46-a6bc-5c164f042fde',
      label: 'Length',
    },
    TRIPLES: {
      uuid: 'c3481a59-7a2c-4eec-9fb5-766f545b8e29',
      label: 'Triples',
    },
  },
};

export const MEMORY = {
  ALL_SETS_TO_SET_IDS: 'all-sets-to-set-ids',
  CONTAINED: 'contained',
  CREATED: 'created',
  MMD_DIFF_SIZE: 'mmd-diff-size',
  MMD_EVENT_TYPE: 'mmd-event-type',
  MMD_MOUSE_DOWN: 'mmd-mouse-down',
  MMD_MD_TIMESTAMP: 'mmd-mouse-down-timestamp',
  MMD_MM_TIMESTAMP: 'mmd-mouse-move-timestamp',
  NAV_PANNING: 'nav-panning',
  NAV_PREV_MOUSE: 'nav-prev-mouse',
  NEW_ENTITY_IDS: 'new-entity-ids',
  NODES_TO_BUILD: 'nodes-to-build',
  NODES_TO_FIT: 'nodes-to-fit',
  RIGHT_CLICK_PANNING: 'rc-panning',
  SELECTED_MORE_THAN_1: 'selected-more-than-1',
  SELECT_SHAPE_ID: 'select-shape-id',
  SHIFT_DOWN: 'shift-down',
} as const;

export type EventModeTypes = {
  [Key in Uppercase<EventMode>]: `${Lowercase<Key>}`;
};

export const EventModeType: EventModeTypes = {
  NONE: 'none',
  PASSIVE: 'passive',
  AUTO: 'auto',
  DYNAMIC: 'dynamic',
  STATIC: 'static',
} as const;

// These constants may have any owner in the event system- including, but not limited to, 'global';
// They existed before the concept of a 'global' owner
export const GLOBAL_EVENT = {
  EXIT: {
    SAYS_HISTORY: 'event/global/exit/SAYS_HISTORY',
    SAYS_QPV: 'event/global/exit/SAYS_QPV',
  },
  SHAPE_ADD: 'event/global/shape/ADD',
  SHAPES_ADD: 'event/global/shapes/ADD',
  SHAPE_CONFIGS_ADD: 'event/global/shape_configs/ADD',
  SHAPES_CLEAR: 'event/global/shapes/CLEAR',
  SHAPES_UPDATE: 'event/global/shapes/UPDATE',
  SHAPES_DELETE: 'event/global/shapes/DELETE',
  SHAPE_LOADED: 'event/global/shapes/LOADED',
  PIXI_CLEAR: 'event/global/pixi/CLEAR',
  PIXI_RELOAD_SHAPES: 'event/global/pixi/shapes/RELOAD',
  PIXI_RENDER: 'event/global/pixi/RENDER',
  PIXI_RERENDER: 'event/global/pixi/RERENDER',
  PIXI_RESIZE: 'event/global/pixi/RESIZE',
  MULTI_SELECT_MOVE: 'event/global/MULTI_SELECT_MOVE',
  MULTI_SELECT_MOVE_END: 'event/global/MULTI_SELECT_MOVE_END',
  SHOW_GRID: 'event/global/SHOW_GRID',
  UPDATE_GRID_SETTINGS: 'event/global/UPDATE_GRID_SETTINGS',
  TOGGLE_DISPLAY_EDGE_LABELS: 'event/global/TOGGLE_DISPLAY_EDGE_LABELS',
  TOGGLE_DISPLAY_NODE_LABELS: 'event/global/TOGGLE_DISPLAY_NODE_LABELS',
  TOGGLE_BACKGROUND_LOCK: 'event/global/toggle/background/LOCK',
  TOGGLE_SET_MEMBERSHIP_DISPLAY: 'event/global/TOGGLE_SET_MEMBERSHIP_DISPLAY',
  TOGGLE_SET_VISIBILITY: 'event/global/toggle/SET_VISIBILITY',
  REBUILD_QUAD_TREE: 'event/global/REBUILD_QUAD_TREE',
  HISTORY_UNDO: 'event/global/HISTORY_UNDO',
  HISTORY_REDO: 'event/global/HISTORY_REDO',
  NOTIFY: {
    GRID: {
      ENTITIES: {
        CREATED: 'event/global/notify/grid/entities/CREATED',
      },
    },
  },
  UPDATE: {
    SELECTED: {
      ALPHA: {
        BY_TYPE: 'model/engine/update/selected/alpha/BY_TYPE',
      },
      COLOR: {
        BY_TYPE: 'model/engine/update/selected/color/BY_TYPE',
      },
    },
  },
  QPV: {
    CLOSE: 'event/global/qpv/CLOSE',
    OPEN: 'event/global/OPEN',
  },
} as const;

export const EXPRESSIONS = {
  ERROR_MESSAGE: 'ERROR',
  ONE_BASED_INDEX: false,
};

export const GRID = {
  NAME_COLUMN: {
    NODES: 'name/column/NODES',
    EDGES: 'name/column/EDGES',
    SETS: 'name/column/SETS',
    MODEL_PROPS: 'name/column/MODEL_PROPS',
    MODEL_CALCS: 'name/column/MODEL_CALCS',
    PATHS: 'name/column/PATHS',
  } as const,
  NAME_COLUMN_TYPES: {
    'name/column/NODES': 'nodes',
    'name/column/EDGES': 'edges',
    'name/column/SETS': 'sets',
    'name/column/MODEL_PROPS': 'modelProps',
    'name/column/MODEL_CALCS': 'modelCalcs',
    'name/column/PATHS': 'paths',
  } as const,
  NAME_ROW: {
    NODES: 'name/row/NODES',
    EDGES: 'name/row/EDGES',
    SETS: 'name/row/SETS',
    MODEL_PROPS: 'name/row/MODEL_PROPS',
    MODEL_CALCS: 'name/row/MODEL_CALCS',
    PATHS: 'name/row/PATHS',
  } as const,
  NAME_ROW_TYPES: {
    'name/row/NODES': 'nodes',
    'name/row/EDGES': 'edges',
    'name/row/SETS': 'sets',
    'name/row/MODEL_PROPS': 'modelProps',
    'name/row/MODEL_CALCS': 'modelCalcs',
    'name/row/PATHS': 'paths',
  } as const,
} as const;

export declare type GridNameColumnValues = ValueOf<typeof GRID.NAME_COLUMN>;
export declare type GridNameRowValues = ValueOf<typeof GRID.NAME_ROW>;

// sets the archive type currently viewed
export enum ARCHIVE_TYPE {
  MEDIA = 'archive/type/MEDIA',
  NONE = 'archive/type/NONE',
  PROJECTS = 'archive/type/PROJECTS',
}

export const STANDARD_ROW_SIZE = 35;
export const STANDARD_COL_SIZE = 100;

export const ACTIONS = {
  // DefaultActions legacy events
  DESELECT: 'action/DESELECT',
  DESELECT_ALL: 'action/DESELECT_ALL',
  CHANGE_ALL_EDGES_ALPHA: 'action/CHANGE_ALL_EDGES_ALPHA',
  CHANGE_ALL_NODES_ALPHA: 'action/CHANGE_ALL_NODES_ALPHA',
  CHANGE_ALL_SETS_ALPHA: 'action/CHANGE_ALL_SETS_ALPHA',
  CHANGE_EDGE_ALPHA: 'action/CHANGE_EDGE_ALPHA',
  CHANGE_NODE_ALPHA: 'action/CHANGE_NODE_ALPHA',
  CHANGE_SET_ALPHA: 'action/CHANGE_SET_ALPHA',
  CLEAR_FILTER: 'action/CLEAR_FILTER',
  RESET_EDGE_ALPHA: 'action/RESET_EDGE_ALPHA',
  RESET_NODE_ALPHA: 'action/RESET_NODE_ALPHA',
  RESET_SET_ALPHA: 'action/RESET_SET_ALPHA',
  SELECT: 'action/SELECT',
  SELECT_ALL: 'action/SELECT_ALL',
  TOGGLE_COLLAPSE_SET: 'action/TOGGLE_COLLAPSE_SET',
  TOGGLE_COLLAPSE_SETS: 'action/TOGGLE_COLLAPSE_SETS',

  // Special cases not fired by `DefaultActions`
  SELECT_ONE: 'actions/SELECT_ONE',
  SELECT_MULTI: 'actions/SELECT_MULTI',
  DESELECT_SETS_ALL: 'actions/deselect/sets/ALL',
  DESELECT_SETS_IDS: 'actions/deselect/sets/IDS',
  DESELECT_SHAPES_ALL: 'actions/deselect/shapes/ALL',
  DESELECT_SHAPES_IDS: 'actions/deselect/shapes/IDS',
} as const;

export const MAC_OS = 'MacOS' as const;
export const I_OS = 'iOS' as const; // iphone, ipod, ipad
export const WINDOWS_OS = 'Windows' as const;
export const ANDROID_OS = 'Android' as const;
export const LINUX_OS = 'Linux' as const;

// export const OS_TYPES: OSTypes[] = [
//   MAC_OS | I_OS | WINDOWS_OS | ANDROID_OS | LINUX_OS,
// ];
export const CSV_IMPORT = {
  DESTINATION: {
    NODES_TAB: 'nodes',
    SETS_TAB: 'sets',
    MODEL_TAB: 'model',
    CALC_TAB: 'calc',
  },
};

/**
 * The path action types (enum).
 */
export const PATH = genericActionsForType('model/paths', ['initialize']);
